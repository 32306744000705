import React from "react";
import { useTranslation } from "react-i18next";
import './CustomCheckbox.css'

const CustomCheckbox = ({handleChange, defaultValue}) => {
  
  const {t} = useTranslation()

  return (
    <label className="checkbox-container  address-details-form">
      <label className="save-address-label">{t("remember_address")}</label>
      <input type='checkbox' className='custom-checkbox' onChange={handleChange} checked={defaultValue}/>
      <span className='checkmark'></span>
    </label>
  )
}

export default CustomCheckbox