import React from "react";
import { useState } from "react";
import DropdownPolygon from "../uiElements/icons/DropdownPolygon";

const CountryOption = ({
  country,
  setCountry,
  toggleDropdown,
  handleSetCountry,
}) => {
  const handleCountryClick = (country) => {
    const e = {
      target: {
        name: "country",
        value: country,
      },
    };
    setCountry(country);
    toggleDropdown(false);
    handleSetCountry(e);
  };

  return (
    <li
      className="country-option"
      onClick={() =>
        handleCountryClick({
          countryId: `${country.id}`,
          countryName: country.name,
        })
      }
    >
      <span className="flag-container">
        <img
          src={country?.flag}
          alt="country flag"
          className="coutry-dropdown-flag"
          height={14}
          width={22}
        />
      </span>
      <span>{country?.name}</span>
    </li>
  );
};

const CountrySelectDropdown = ({
  countries,
  tabIndex,
  defaultCountry,
  handleSetCountry,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const selectedFlag = countries.find(
    (c) => c.id === +defaultCountry?.countryId
  )?.flag;
  const countryName = countries.find(
    (el) => +defaultCountry?.countryId === el.id
  )?.name;

  return (
    <div className="dropdown-main-container">
      <span className="input-flag-adornment">
        <img
          src={selectedFlag}
          alt=""
          className="coutry-dropdown-flag"
          height={14}
          width={22}
        />
      </span>
      <span className="country-dropdown-arrow-container">
        <DropdownPolygon />
      </span>
      <input
        className="checkout-input address-input select-country"
        tabIndex={tabIndex}
        name="country"
        value={countryName || ""}
        readOnly
        onClick={() => setShowDropdown((prevState) => !prevState)}
      />
      {showDropdown ? (
        <ul className="coutry-options-container">
          {countries.map((c) => (
            <CountryOption
              selectedCountry={selectedCountry}
              handleSetCountry={handleSetCountry}
              key={c?.id}
              country={c}
              setCountry={setSelectedCountry}
              toggleDropdown={setShowDropdown}
            />
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default CountrySelectDropdown;
