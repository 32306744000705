import React, { useState, useEffect } from 'react';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';

const Ads = () => {
  const [adsScriptLoaded, setAdsScriptLoaded] = useState(false);

  useEffect(() => {
    if (!adsScriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://a.teads.tv/page/125170/tag';
      script.classList.add('teads');
      script.async = true;
      // document.body.appendChild(script);
      setAdsScriptLoaded(true);
    }
  }, [adsScriptLoaded]);

  return (
    <article className='ads-article'>
      <section className='ad-section'>
        <p>Google Ads</p>

        <DFPSlotsProvider dfpNetworkId=''>
          {/* <AdSlot
            adUnit='/6355419/Travel/Europe/France/Paris'
            sizes={[
              [300, 250],
              [300, 600]
            ]}
          /> */}
          <AdSlot
            adUnit='/27367402/360VUZ_IOS_TEST'
            sizes={[
              [300, 50],
              [300, 250],
              [320, 50],
              [320, 100]
            ]}
          />
        </DFPSlotsProvider>
      </section>

      <section className='ad-section'>
        <p>Teads Ads:</p>
        <div className="teads-ad-section" id='teads-ad-section'>

        </div>
      </section>
    </article>
  );
};

export default Ads;
