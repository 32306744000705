// import step1 from '../assets/icons/step1-icon.svg';

const Icons = {
  back: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/back.png',
  backAr: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/back-ar.png',
  buyPoints: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/buy-points.png',
  menu: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/menu.png',
  orders: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/orders.png',
  support: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/support.png',
  address: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/address.png',
  diamonds: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/diamonds.png',
  diamond: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/diamond.png',
  dollar: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/dollar-ic.png',
  box: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/box.png',
  fire: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/fire.png',
  crown: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/crown.png',
  logo: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/logo.png',
  vipDiscountSm: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/shop-vip-label-small.png',
  vipDiscountLg: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/shop-vip-label-large.png',
  vipBadge: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/vip-badge.png',
  ccImg: 'https://dxmckoh3avtig.cloudfront.net/images/app/plus/icons/cc-img.png',
  shield: 'https://dxmckoh3avtig.cloudfront.net/images/app/plus/icons/shield.svg',
  plus: 'https://dxmckoh3avtig.cloudfront.net/images/app/plus/shop_ic_add.svg',
  cvv: 'https://dxmckoh3avtig.cloudfront.net/images/app/plus/icons/cvv-icon-sm.png',
  help: 'https://dxmckoh3avtig.cloudfront.net/images/app/plus/icons/cvv-help-icon.png',
  errorIcon: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop/shop_img_error.svg',
  success: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop/shop_img_orderplaced.svg',
  inquiry: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/inquiry.png',
  noOrders: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/no-orders.png',
  noOrderss: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/no-orderss.png',
  product1: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/product1.png',
  pointsAndLeft: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/points-and-left.png',
  priceAndSize: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop-v2/images/price-and-size.png',
  fullLogo: 'https://dxmckoh3avtig.cloudfront.net/images/app/plus/images/logo1.png',
  closeBtn: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop/btn_close.svg',
  carouselBtn: 'https://dxmckoh3avtig.cloudfront.net/images/app/shop/carouselButton.svg'
};

export default Icons;
