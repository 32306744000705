import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { Col } from "react-bootstrap";
import CustomCheckbox from "./uiElements/form/CustomCheckbox";

export default function AddressDetailsButton({
  isMobile,
  tabIndex,
  buttonDisabled,
  handleSetFormData,
  isLoading,
  addressDetails,
  handleInputFocus,
  handleInputOnBlur,
  toggleSaveAddress,
  saveAddress,
}) {
  const [value, setValue] = useState(addressDetails.city || '');
  const { t } = useTranslation();

  useEffect(() => {
    setValue(addressDetails.city)
  }, [addressDetails.city])
  return (
    <>
      <Col xs={12} className="mb-4">
        <label className="vuz-label">{t("city")}</label>
        <input
          tabIndex={tabIndex}
          name="city"
          className="checkout-input address-input"
          placeholder={t("your_city")}
          defaultValue={addressDetails?.city || ""}
          onFocus={handleInputFocus}
          onBlur={handleInputOnBlur}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </Col>
        <CustomCheckbox
          handleChange={toggleSaveAddress}
          defaultValue={saveAddress}
        />
      <div className="product-details-actions-div2">
        <div
          className={`submit-container w-100 ${
            !isMobile && "submit-container-mobile d-flex justify-content-center"
          }`}
        >
          <button
            tabIndex={tabIndex}
            className={`confirm-btn ${isLoading ? "loading-button" : ""} address-details-confirm-btn`}
            disabled={!value.trim() || buttonDisabled || isLoading}
            onClick={handleSetFormData}
          >
            {isLoading ? (
              <Spinner
                className="loading-spinner"
                animation="border"
                variant="light"
              />
            ) : (
              t("save")
            )}
          </button>
        </div>
      </div>
    </>
  );
}
