import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CarouselButtons from './CarouselButtons';
import Helpers from '../../utils/Helpers';

const FreeCarousel = ({
  ItemComponent,
  data,
  itemsToShow = 2,
  showHalfOfNext = false,
  showDots = false,
  sectionId,
  minCount,
  childCount
}) => {
  const { i18n } = useTranslation();
  const isDirRtl = i18n.dir() === 'rtl';
  const isWeb = Helpers.getIsWeb();
  const isMobile = Helpers.getIsMobile();
  const isTablet = Helpers.getIsTablet();
  const ref = useRef(null);
  const el = document.getElementById(sectionId);
  const elScrollLeft = el?.scrollLeft;
  const elScrollWidth = el?.scrollWidth;
  const elOffsetWidth = el?.offsetWidth;

  const [scroll, setScroll] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  const scrollSizeLimit = isMobile ? 2 : (isTablet ? 4 : 3);
  const scrollSize = isMobile ? 175 : 190;
  const isScrollStart = scroll === 0;
  const scrollable = childCount > minCount;
  const scrollLimit = (childCount - scrollSizeLimit) * scrollSize;
  const maxScroll = isMobile ? 15 : 150;

  const handleCarouselScroll = (back = false) => {
    const el = document.getElementById(sectionId);

    if (back) {
      if (!isScrollStart || (isScrollStart && isDirRtl)) {
        el.scrollLeft -= scrollSize;
        setScroll((prevState) => prevState - scrollSize);
      }
    } else {
      if (scroll !== scrollLimit) {
        el.scrollLeft += scrollSize;
        setScroll((prevState) => prevState + scrollSize);
      }
    }
  };

  const onScroll = () => {
    const scrollLeft = ref.current.scrollLeft;
    setScroll(scrollLeft);
  };

  useEffect(() => {
    if (el) {
      const limit = (elScrollWidth - elOffsetWidth);
      const end = isDirRtl ? -limit : limit;
      const isEnd = elScrollLeft === end;

      setScrollEnd(isEnd);
    }
  }, [isDirRtl, el, elScrollLeft, elScrollWidth, elOffsetWidth])

  return (
    <div
      className={`carousel-outer-div ${showHalfOfNext && 'carousel-show-half-of-next'} ${isWeb && 'carousel-outer-div-lg'}`}
    >
      <div
        className={`horizontal-carousel ${isWeb && 'horizontal-carousel-lg'}`}
        id={sectionId}
        ref={ref}
        onScroll={onScroll}
      >
        {isWeb && !isMobile && (
          <CarouselButtons
            scroll={scroll}
            scrollLimit={scrollLimit}
            scrollable={scrollable}
            maxScroll={maxScroll}
            isDirRtl={isDirRtl}
            isScrollStart={isScrollStart}
            scrollEnd={scrollEnd}
            handleCarouselScroll={handleCarouselScroll}
          />
        )}
        {data &&
          data.length > 0 &&
          data.map((dataItem) => (
            <div className={`carousel-item-div ${isWeb && 'carousel-item-div-lg'}`} key={Math.random()}>
              <ItemComponent data={dataItem} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FreeCarousel;
