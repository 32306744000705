//testing last stable version of checkout
import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
//import { useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import axios from "axios";

import BackHeader from "../layout/BackHeader";
import DynamicScrollToTop from "../layout/DynamicScrollToTop";
import AddressDetails from "./AddressDetails";
import HeaderWeb from "../layout/HeaderWeb";
import CustomModal from "../shop/Modal";
import CheckoutProductDetails from "../shop/CheckoutProductDetails";
import CustomerSupportButton from "../shop/CustomerSupportButton";
import TotalPrice from "../shop/TotalPrice";
//import CardPaymentForm from "../shop/CardPaymentForm";
import PointsStatus from "../shop/PointsStatus";
// import IncDecButton from '../shop/IncDecButton'; // left for further versions when adding items count is reqired

import paymentService from "../../api/paymentService";
import Helpers from "../../utils/Helpers";
import useWindowWidth from "../hooks/UseWindowWidth";
import Icons from "../../constants/Icons";
import AddressService from "../../api/addressService";

const customerDetailsFields = [
  "email",
  "name",
  "phone",
  "address",
  "city",
  "country",
];

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const routeParams = useParams();
  const isAr = Helpers.getIsAR();
  // const location = useLocation()
  const isWeb = Helpers.getIsWeb();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const productId =
    Helpers.getUrlParameter("productId") || routeParams.productId;
  const mainProductId = window.location.href.split("/")[5];
  const getProductDetailsUrl = `${process.env.REACT_APP_API_URL}/shopv2/get/product?productId=${productId}`;
  const getUserProfileUrl = `${process.env.REACT_APP_API_URL}/subscribers/me/profile`;
  const appText = Helpers.getAppText();
  const i18nLang = localStorage.getItem("i18nextLng");
  const lang =
    Helpers.getUrlParameter("language") ||
    localStorage.getItem("language") ||
    "en";
  const paymentOption = Helpers.getUrlParameter("payment") || "card";
  //const elements = useElements();

  const userToken = Helpers.getUserToken() || "";

  const config = Helpers.getAxiosHeadersConfig();
  const countriesInitailState = {
    allCountries: [],
    availableShippingCountries: [],
  };

  const initialState = {
    customerDetails: {
      id: null,
      email: "",
      name: "",
      phone: "",
      address: "",
      city: "",
      countryId: "",
      country: "",
    },
    productId: parseFloat(productId),
  };

  const [productDetails, setProductDetails] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [isUserVip, setIsUserVip] = useState(false);
  const [shippingFee, setShippingFee] = useState(0);
  //const [productsCount, setProductsCount] = useState(1); // left for further versions when adding items count is reqired
  const [productDetailsLoading, setProductDetailsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [modalShow, setModalShow] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState(countriesInitailState);
  const [addressDetails, setAddressDetails] = useState(initialState);
  const [headerHeight, setHeaderHeight] = useState(0);

  const { isPurchasable, isRedeemable } =
    Helpers.getBillingOptions(productDetails);
  const userPointsBalance = userDetails?.userGamificationInfo?.points || 0;
  const customerDetailsAreFilled = !Object.values(
    formData.customerDetails
  ).includes("");

  setTimeout(() =>
    setHeaderHeight(
      document.getElementsByClassName("back-div")[0]?.clientHeight
    )
  );

  const validationFailed = () => {
    if (paymentOption !== "points") {
      return !customerDetailsAreFilled;
    }
    return (
      !customerDetailsAreFilled || userPointsBalance < productDetails?.price
    );
  };

  const billingOptionsValidationFailed = () => {
    return paymentOption === "points"
      ? !isRedeemable
      : paymentOption === "card"
      ? !isPurchasable
      : true;
  };

  const buttonDisabled = validationFailed() || billingOptionsValidationFailed();

  const getProductDetails = async () => {
    setProductDetailsLoading(true);

    try {
      const res = await axios.get(getProductDetailsUrl, config);
      let { data: productDetailsData, status, message } = res.data;
      const product = productDetailsData.find(
        (x) => x.id === parseFloat(productId)
      );
      setProductDetails(product);

      if (status === "success") {
      } else {
        console.log(message);
      }

      setProductDetailsLoading(false);
    } catch (err) {
      setProductDetailsLoading(false);
      console.log("getProductDetailsResponseError", err);
    }
  };

  const getUserDetails = async () => {
    if (userToken) {
      try {
        const res = await axios.get(getUserProfileUrl, config);

        const { data: userData } = res.data;
        if (userData) {
          setUserDetails(userData);
        }

        if (userData && userData.isPaid) {
          setUserDetails(userData);
          setIsUserVip(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // useEffect(()=> { // to add after main features are finished
  // 	if (isWeb && paymentOption === 'points') {
  // 		const search = location.search
  // 		const newSearch = search.replace('points','card')
  // 		window.location.search = newSearch
  // 	}
  // },[isWeb, paymentOption])

  useEffect(() => {
    if (lang !== i18nLang) {
      i18n.changeLanguage(lang);
      Helpers.setDirection();
    }

    getProductDetails();
    getUserDetails();
  }, [i18nLang, lang]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getAddressDetails();
  }, [countries]);

  useEffect(() => {
    const country = countries.availableShippingCountries.find((el) => {
      return el.id === +addressDetails.countryId;
    })?.name;

    const shippingFee = countries.availableShippingCountries.find(
      (c) => c.name === country
    )?.shippingFee;
    setShippingFee(shippingFee);
  }, [addressDetails]);

  const getAddressDetails = async () => {
    if (isWeb) {
      const savedAddressDetails = localStorage.getItem("address_details");
      if (savedAddressDetails) {
        const parsedData = JSON.parse(savedAddressDetails);
        setAddressDetails(parsedData);
      }
    } else {
      const addressData = await AddressService.getUserAddress(config);
      if (addressData) {
        setAddressDetails((prev) => ({
          ...prev,
          ...addressData,
        }));
      }
    }
  };

  const getCountries = async () => {
    const { allCountries, availableCountries } =
      await AddressService.getAllCountries(config);

    setCountries((prev) => ({
      ...prev,
      allCountries: allCountries,
      availableShippingCountries: availableCountries,
    }));
  };

  const handleBuy = async () => {
    setLoading(true);
    Helpers.runTrackEvents("buy_now_button_clicked", {
      product_id: productDetails.id,
      product_name: productDetails.name,
      source: "card checkout screen",
    });
    if (paymentOption === "points") {
      redeemCheckout();
    } else {
      const url = await stripeCheckout();
      window.location.href = url;
    }
  };
  const redeemCheckout = async () => {
    setLoading(true);
    const payload = {
      productId: productDetails.id,
      deliveryAddressId: formData.customerDetails.id,
      deliveryAddress: {
        phone: formData.customerDetails.phone,
        email: formData.customerDetails.email,
        fullName: formData.customerDetails.name,
        address: formData.customerDetails.address,
        city: formData.customerDetails.city,
        countryId: formData.customerDetails.countryId,
      },
    };
    try {
      const res = await paymentService.getRedeemCheckoutData(payload, config);
      const status = res.status;
      if (status === "success") {
        Helpers.runTrackEvents("redeem_product_result", {
          product_id: productDetails.id,
          product_name: productDetails.name,
        });
        setLoading(false);
        setSuccessModal(true);
      } else if (status === "error") {
        Helpers.runTrackEvents("redeem_product_failed", {
          product_id: productDetails.id,
          product_name: productDetails.name,
        });
        setLoading(false);
        setErrorModal(true);
        setErrorMessage(res.message);
      }
    } catch (err) {
      Helpers.runTrackEvents("redeem_product_failed", {
        product_id: productDetails.id,
        product_name: productDetails.name,
      });
      setLoading(false);
      setErrorModal(true);
      setErrorMessage(err.message);
    }
  };
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorModal(false);
  };
  const stripeCheckout = async () => {
    setLoading(true);
    const source = isWeb ? "web" : "app";

    const payload = {
      subscriberId: `${userDetails?.id || ""}` || "",
      productId: +productId,
      deliveryAddressId: formData.customerDetails.id,
      deliveryAddress: {
        phone: formData.customerDetails.phone,
        email: formData.customerDetails.email,
        fullName: formData.customerDetails.name,
        address: formData.customerDetails.address,
        city: formData.customerDetails.city,
        countryId: formData.customerDetails.countryId,
      },
      source: source,
    };

    try {
      const res = await paymentService.getStripeCheckoutData(payload, config);
      const redirectionUrl = res?.data?.data?.redirectionUrl;
      const status = res.status;

      if (status === "success") {
        Helpers.runTrackEvents("buy_product_result", {
          product_id: productDetails.id,
          product_name: productDetails.name,
        });

        if (redirectionUrl) {
          return redirectionUrl;
        }
      } else if (status === "error") {
        Helpers.runTrackEvents("product_purchase_failed", {
          product_id: productDetails.id,
          product_name: productDetails.name,
        });
        setLoading(false);
        setErrorModal(true);
        setErrorMessage(res.message);
      }
    } catch (err) {
      Helpers.runTrackEvents("product_purchase_failed", {
        product_id: productDetails.id,
        product_name: productDetails.name,
      });
      setLoading(false);
      setErrorModal(true);
      console.log(err);
    }
  };

  const handleAddAddressClick = () => {
    Helpers.runTrackEvents("add_address_clicked", {
      source: "card checkout screen",
    });
    setOpen(true);
  };

  const pageTitle = (
    <span className="checkout-title">
      <span className="page-name">{t("purchase")}</span>
      <span className="buy-product-name">“{productDetails?.name}”</span>
      <span className="checkout-description">{t("fill_the_form")}</span>
    </span>
  );

  return (
    <>
      {!isMobile && isWeb && <HeaderWeb />}
      {!isMobile && <CustomerSupportButton />}
      <div
        style={{ paddingTop: `${headerHeight + 60}px` }}
        className="container container-lg container-web checkout-container"
      >
        <div
          className={`checkout-container position-relative ${
            open && "checkout-sm"
          }`}
        >
          <div>
            <DynamicScrollToTop />
            <BackHeader
              title={open ? t("address_details") : pageTitle}
              url={`/shop/products/${mainProductId}`}
              offCanvas={open}
              closeContainer={open ? setOpen : null}
            />
            <section className="checkout-page checkout-main">
              <div className="checkout-section-separator" />
              {productDetailsLoading ? (
                <div className="loader mt-5" />
              ) : (
                <div className={"product-details-div product-details-div-web"}>
                  <Container className="pt-2">
                    <Row>
                      <Col xs={12} className="form-title">
                        {t("ship_to")}
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                      {!!formData.customerDetails.phone ? (
                        <>
                          <Col xs={10} className="address-info">
                            <span className="user-name">
                              {formData.customerDetails.name}
                            </span>
                            <span>{formData.customerDetails.email}</span>
                            <span style={{ direction: "ltr" }}>
                              {formData.customerDetails.phone}
                            </span>
                            <span>{formData.customerDetails.address}</span>
                            <span>{`${formData.customerDetails.city}, ${formData.customerDetails.country}`}</span>
                          </Col>
                          <Col
                            style={
                              isWeb
                                ? !isAr
                                  ? { paddingRight: "24px" }
                                  : { paddingLeft: "24px" }
                                : null
                            }
                            xs={2}
                          >
                            <Button
                              className="link-button edit-button"
                              variant="link"
                              onClick={() => setOpen(true)}
                            >
                              {t("edit")}
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col xs={12} className="form-title">
                          <Button
                            className="link-button"
                            variant="link"
                            onClick={handleAddAddressClick}
                          >
                            <img alt="plus" src={Icons.plus} />
                            {t("add_address")}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Container>
                  <div className="redeem-content redeem-content-lg">
                    {paymentOption === "points" && (
                      <PointsStatus
                        appText={appText}
                        pointsBalance={userPointsBalance}
                        productId={productId}
                        paymentOption={paymentOption}
                      />
                    )}

                    <CheckoutProductDetails
                      productDetails={productDetails}
                      paymentOption={paymentOption}
                    />
                  </div>
                  <div
                    style={isWeb && isMobile ? { maxWidth: "none" } : null}
                    className={"checkout-footer"}
                  >
                    <div className="total-cost">
                      <TotalPrice
                        productDetails={productDetails}
                        shippingFee={shippingFee}
                        // count={productsCount}
                        isUserVip={isUserVip}
                        showShippingCost={customerDetailsAreFilled}
                        paymentOption={paymentOption}
                      />
                    </div>

                    <div className="submit-container w-100 pos-rel">
                      {isUserVip && paymentOption !== "points" && (
                        <img
                          src={Icons.vipDiscountSm}
                          alt="VIP"
                          className="vip-product vip-product-sm"
                        />
                      )}
                      <button
                        className="confirm-btn"
                        disabled={buttonDisabled || loading}
                        onClick={handleBuy}
                      >
                        {loading ? (
                          <Spinner
                            className="loading-spinner"
                            animation="border"
                            variant="light"
                          />
                        ) : paymentOption === "points" ? (
                          appText.redeem_with_points
                        ) : (
                          appText.buy_now
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>

          <AddressDetails
            formData={formData}
            open={open}
            setOpen={setOpen}
            setFormData={setFormData}
            setShippingFee={setShippingFee}
            customerDetailsFields={customerDetailsFields}
          />
          <CustomModal
            icon={<img alt="" src={Icons.cvv} width={170} height={100} />}
            description={<p>{t("cvv_info")}</p>}
            text={t("ok")}
            show={modalShow}
            onHide={() => setModalShow(false)}
            userToken={userToken}
          />

          <CustomModal
            icon={<img alt="" src={Icons.errorIcon} width={140} />}
            description={
              <>
                <h5>{errorMessage || t("smth_went_wrong")}</h5>
              </>
            }
            text={t("ok")}
            show={errorModal}
            onHide={handleErrorClose}
          />
          <CustomModal
            className="success"
            icon={<img alt="" src={Icons.success} width={140} />}
            description={
              <>
                <h5>{t("order_placed")}</h5>
                <p>{t("order_placed_successfully")}</p>
              </>
            }
            text={t("continue_shopping")}
            show={successModal}
            action
            onHide={() => setSuccessModal(false)}
          />
        </div>
      </div>
    </>
  );
};

export default Checkout;
