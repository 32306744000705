import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import languages from '../../constants/languages';

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const i18Language = localStorage.getItem('i18nextLng');

  const lang = i18Language === 'en' ? t('english') : t('arabic');

  const [selectedLanguage, setSelectedLanguage] = useState({ lang: i18Language, langName: lang });

  const handleChangeLanguage = (language) => {
    localStorage.setItem('language', language.lang);
    i18n.changeLanguage(language.lang)
    setSelectedLanguage(language);
  };

  useEffect(() => {
    setSelectedLanguage({ lang: i18Language, langName: lang });
  }, [lang, i18Language]);

  return (
    <Dropdown className='language-dropdown'>
      <Dropdown.Toggle id="dropdown-basic">
        {selectedLanguage.langName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.map((item) => (
            <Dropdown.Item
              key={item.lang}
              onClick={() => handleChangeLanguage({
                lang: item.lang,
                langName: item.name
              })}
            >
              {item.name}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
