import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Helpers from "../../utils/Helpers";
import useWindowWidth from "../hooks/UseWindowWidth";
import Icons from "../../constants/Icons";

const ProductItem = ({ data: product }) => {
  const { t } = useTranslation();
  const appText = Helpers.getAppText();
  const isWeb = Helpers.getIsWeb();
  const userToken = Helpers.getUserToken() || "";
  const isMobile = useWindowWidth() <= 768;
  const [expiryCountdown, setExpiryCountdown] = useState("");
  const navigate = useNavigate();

  const onClickProductItem = (selectedProduct) => {
    Helpers.runTrackEvents("product_details_screen", {
      product_id: selectedProduct.id,
      product_name: selectedProduct.name,
    });

    window.trackEvent("button_view_clicked", {
      source: "shop",
      product_id: selectedProduct.id,
      is_guest: !userToken,
    });

    let userTokenQueryString = "";
    if (userToken) {
      userTokenQueryString = `?userToken=${userToken}`;
    }
    navigate(
      `/shop/products/${selectedProduct.id}${userTokenQueryString}${
        isWeb ? "?source=web" : ""
      }`
    );
  };

  const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  };

  useEffect(() => {
    if (product.expirationDate) {
      setInterval(() => {
        const then = moment(product.expirationDate, "YYYY-MM-DD hh:mm:ss");
        const now = moment();
        const countdown = then.diff(now);
        const tempTime = moment.duration(countdown);
        const days = tempTime.days();
        const hours = addLeadingZeros(tempTime.hours());
        const minutes = addLeadingZeros(tempTime.minutes());
        const seconds = addLeadingZeros(tempTime.seconds());
        if (days && days > 0) {
          if (days > 1) {
            setExpiryCountdown(`${days} ${appText.days_left}`);
          } else {
            setExpiryCountdown(`${days} ${appText.day_left}`);
          }
        } else {
          setExpiryCountdown(`${hours}:${minutes}:${seconds}`);
        }
      }, 1000);
    }
  }, [appText.days_left, product.expirationDate, appText.day_left]);

  return (
    <div
      className={`product-item-div ${isWeb && "product-item-div-lg"} ${
        isMobile && isWeb && "product-item-div-mobile"
      }`}
      onClick={() => onClickProductItem(product)}
    >
      <div className="product-item-shop-header">
        {product.shop.backgroundImageURL &&
          !product.shop.backgroundImageURL.includes("plus") && (
            <div className="shop-img-in-product-container">
              <img
                className="shop-img-in-product"
                alt=""
                src={product.shop.backgroundImageURL}
              />{" "}
            </div>
          )}
        <div className="product-shop-div">
          <p className="shop-name-in-product">{product.shop.title}</p>
          <p className="merch">{t("merch")}</p>
        </div>
      </div>
      <div className="product-img-div">
        {expiryCountdown && (
          <span className="product-expiration">{expiryCountdown}</span>
        )}
        <img className="product-img" alt="" src={product.backgroundImageURL} />
      </div>
      <div className="product-name-div">
        <p className="product-name">{product.name}</p>
      </div>
      <div className="two-items-flex-div mb-1 px-1">
        <p className="product-cost">
          {product.price && !isWeb ? (
            <>
              <img
                src={Icons.diamond}
                className="diamond-img"
                alt="diamond img"
              />
              {product.price}
            </>
          ) : (
            <>
              <img src={Icons.dollar} className="dollar-img" alt="dollar img" />
              <span>{product.priceMoney.split(".")[0]}</span>
            </>
          )}
        </p>
        <p className="amount-left-text">
          {" "}
          <img src={Icons.fire} className="fire-img" alt="fire img" />
          {product.totalStock} {t("left")}
        </p>
      </div>
      <button className="shop-btn full dark">{t("view")}</button>
    </div>
  );
};

export default ProductItem;
