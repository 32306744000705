const languages = [
  {
    lang: 'en',
    name: 'Eng',
    direction: 'ltr'
  },
  {
    lang: 'ar',
    name: 'العربية',
    direction: 'rtl'
  }
];

export default languages;
