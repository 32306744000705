import React from 'react';

import Helpers from '../../utils/Helpers';

const ProductImage = ({ data: productImage }) => {
  const isWeb = Helpers.getIsWeb();

  return (
    <img
      src={productImage}
      alt=''
      className={`product-details-img ${isWeb && 'product-details-img-lg'}`}
    />
  );
};

export default ProductImage;
