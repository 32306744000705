import React from 'react';
import { useNavigate } from 'react-router-dom';

import CommonHeader from './CommonHeader';

import Helpers from '../../utils/Helpers';
import Icons from '../../constants/Icons';

const BackHeader = ({ title, offCanvas = false, closeContainer, url, direction }) => {
  const isWeb = Helpers.getIsWeb();
  const navigate = useNavigate()
  const language = Helpers.getUrlParameter('language') || localStorage.getItem('language') || 'en';

  const handleBack = () => {
    if (url) {
      navigate(url)
    } else {
    navigate(-1)
    }
  };

  const handleCloseOffCanvas = () => {
    closeContainer(false);
  }

  const backIcon = (className) => (
    <div className={`back-div ${className}`} onClick={offCanvas ? handleCloseOffCanvas : handleBack}>
      <img
        className='back-img'
        alt='go-back'
        src={language === 'ar' ? Icons.backAr : Icons.back} />
    </div>
  );

  return (
    <section dir={direction} className={`back-header-section ${isWeb && 'back-header-section-lg'}`}>
      <CommonHeader />
      <div className={`page-name-div page-name-div-lg`}>
        {backIcon()}
        <p className='page-name'> {title}</p>
        {backIcon('visibility-h')}
      </div>
    </section>
  );
};

export default BackHeader;
