import axios from "axios";
import Helpers from "../utils/Helpers";

export default {
  getAllCountries: async (config) => {
    const url = `${process.env.REACT_APP_API_URL}/countries/list`;
	 
    try {
      const res = await axios.get(url, config);
      const { data } = res.data;

      if (data && data.length > 0) {
        const allCountries = data;
        const countriesWithFlags = data.filter((c) => c.flag);
        const uniqueCountries = Helpers.getUniqueListBy(
          countriesWithFlags,
          "name"
        );

        const availableCountries = uniqueCountries.filter(
          (c) => !!c.isShippingFeeActive
        );

        return {
          allCountries: allCountries || [],
          availableCountries: availableCountries || [],
        };
      }
    } catch (err) {
      console.log("error", err);
    }
  },

  getUserAddress: async (config) => {
    const url = `${process.env.REACT_APP_API_URL}/shopv2/addresses/get/`;

    try {
      const res = await axios.get(url, config);
      const { data } = res.data;

      if (data && data?.length > 0) {
        const lastSavedAddress = data[data.length - 1];
        return {
          id: lastSavedAddress.id || "",
          phone: lastSavedAddress.phone || "",
          email: lastSavedAddress.email || "",
          name: lastSavedAddress.fullName || "",
          address: lastSavedAddress.address || "",
          city: lastSavedAddress.city || "",
          countryId: `${lastSavedAddress.countryId}` || "1",
          country: lastSavedAddress.country.name_en || "UAE",
        };
      }
    } catch (err) {
      console.log("error: ", err);
    }
  },

  saveUserAddress: async (config, payload) => {
    const url = `${process.env.REACT_APP_API_URL}/shopv2/addresses/save`;

    try {
      const res = await axios.post(url, payload, config);
      const { status, data } = res.data;
      const id = data.id;

      if (status !== "success") {
        return res.data
      }
      return { id, status };
    } catch (err) {
      console.log("error", err);
    }
  },
  shouldSaveDeliveryAddress: async (config, payload) => {
    const url = `${process.env.REACT_APP_API_URL}/Subscribers/edit/me`;
    try {
      const res = await axios.post(url, payload, config)
      const {status} = res.data
      console.log(res)
      return {status}
    } catch (err) {
      console.log(err.message)
    }
  },

  getShouldSaveAddress: async (config) => {
    const url = `${process.env.REACT_APP_API_URL}/subscribers/me/profile`;

    try {
      const res = await axios.get(url, config)
      const {shouldSaveDeliveryAddress} = res.data.data
      return shouldSaveDeliveryAddress
    } catch (err) {
      console.log(err.message)
    }
  },
};
