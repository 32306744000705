import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import IntlTelInput from "react-intl-tel-input";

import CountrySelectDropdown from "../shop/CountrySelectDropdown";
import DynamicScrollToTop from "../layout/DynamicScrollToTop";
import CustomModal from "../shop/Modal";
import Icons from "../../constants/Icons";

import AddressService from "../../api/addressService";
import Helpers from "../../utils/Helpers";

import "react-intl-tel-input/dist/main.css";
import BackHeader from "../layout/BackHeader";
import AddressDetailsButton from "../AddressDetailsButton";

const AddressDetails = ({
  open,
  setOpen,
  formData,
  setFormData,
  setShippingFee,
  customerDetailsFields,
}) => {
  const { t, i18n } = useTranslation();
  const isWeb = Helpers.getIsWeb();
  const isAndroid = Helpers.getIsAndroid();
  const getUserCountryDetailsUrl =
    "https://prod-plus-api.360vuz.com/home?source=api-web-lookup";
  const flagContainer = document.getElementsByClassName("selected-flag")[0];

  if (flagContainer) {
    flagContainer.setAttribute("tabIndex", "-1");
  }

  const initialState = {
    phone: "",
    email: "",
    name: "",
    address: "",
    city: "",
    countryId: "",
    country: "",
  };

  const [userData, setUserData] = useState(null);
  const [phone, setPhone] = useState("");
  const [tabIndex, setTabIndex] = useState(-1);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [inputBlurred, setInputBlurred] = useState(false);
  const [addressDetails, setAddressDetails] = useState(initialState);
  const [saveAddress, setSaveAddress] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errors, setErrors] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preferredCountry, setPreferredCountry] = useState({});
  const [countries, setCountries] = useState({
    allCountries: [],
    availableShippingCountries: [],
  });
  const [currentDialCode, setCurrentDialCode] = useState("971");
  const formRef = useRef(null);
  const country = countries.availableShippingCountries.find(
    (c) => c.id === +addressDetails.countryId
  )?.name;
  const config = Helpers.getAxiosHeadersConfig();

  const getPreferredCountry = () => {
    let preferredCountry = userData?.country;
    const available = countries.availableShippingCountries.find(
      (el) => el.isoCode === preferredCountry
    );
    if (available) {
      return available;
    }
    return null;
  };
  useEffect(() => {
    setPreferredCountry(getPreferredCountry());
  }, [countries, userData]);
  const checkButtonDisabled = () => {
    const form = document.getElementById("form");
    const formObj = {
      email: form.email.value,
      phone: form?.phone?.value,
      name: form.name.value,
      address: form.address.value,
    };
    const addressDetailsArr = Object.entries(formObj);
    const validateBtn = addressDetailsArr.every((item, index) => {
      if (item[0] === "email") {
        return item[1].replace("+" + currentDialCode, "");
      }
      return item[1]?.toString().trim().length;
    });
    if (validateBtn) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }

    if (!!errors?.email) {
      setButtonDisabled(true);
    }
  };

  const getUserCountryDetails = async () => {
    try {
      const res = await axios.get(getUserCountryDetailsUrl);
      const { data } = res.data;

      setUserData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleInputOnBlur = () => {
    if (isAndroid) {
      document.getElementsByClassName(
        "product-details-actions-div2"
      )[0].style.paddingBottom = "150px";
    }
  };

  const handleInputFocus = (e) => {
    e.target.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getCountriesList = async () => {
    const { allCountries, availableCountries } =
      await AddressService.getAllCountries(config);
    const sortedAvailableCountries = availableCountries.sort((a, b) => {
      if (a.name > b.name) return 1;
      else if (a.name < b.name) return -1;
      return 0;
    });

    setCountries((prev) => ({
      ...prev,
      allCountries: allCountries,
      availableShippingCountries: sortedAvailableCountries,
    }));
  };
  const getUserAddressDetails = async () => {
    let shouldSaveDeliveryAddress = await AddressService.getShouldSaveAddress(
      config
    );


    if (shouldSaveDeliveryAddress !== false && shouldSaveDeliveryAddress !== 0) {
      shouldSaveDeliveryAddress = true;
    }
    const addressData = await AddressService.getUserAddress(config);
    if (addressData && shouldSaveDeliveryAddress) {
      const country = countries.availableShippingCountries.find(
        (c) => c.id === +addressData.countryId
      )?.name;
      setAddressDetails((prev) => ({
        ...prev,
        ...addressData,
      }));

      setFormData((prev) => ({
        ...prev,
        customerDetails: { ...addressData, country },
      }));
      setPhone(
        Helpers.removeNumDialCode(addressData.phone, countries.allCountries)
      );
    }
    setSaveAddress(shouldSaveDeliveryAddress);
  };
  const saveUserAddressDetails = async (countryId) => {
    const form = document.getElementById("form");
    const body = {
      phone: addressDetails.phone,
      email: addressDetails.email,
      fullName: form.name.value || addressDetails.name,
      address: form.address.value || addressDetails.address,
      city: form.city.value || addressDetails.city,
      countryId: String(countryId),
    };
    const res = await AddressService.saveUserAddress(config, body);
    await AddressService.shouldSaveDeliveryAddress(config, {
      shouldSaveDeliveryAddress: saveAddress,
    });
    if (res?.status !== "success") {
      setErrorModal(
        "Something went wrong, please try again and if the issue persists contact support at support@360mea.com"
      );
      return;
    }
    if (res?.status === "success" && res.id) {
      setFormData((prev) => ({
        ...prev,
        customerDetails: {
          ...prev.customerDetails,
          id: res.id,
        },
      }));

      Helpers.runTrackEvents("address_saved_result", {
        message: "Address saved successfully!",
      });
      setOpen(false);
    }
  };

  const togglePhoneDropdown = () => {
    const dropdown = document.querySelector(".intl-tel-input");
    const countryList = document.querySelector(".country-list");
    const arrow = document.querySelector(".arrow");

    if (dropdown) {
      dropdown.classList.toggle("expanded");
      countryList.classList.toggle("hide");
      arrow.classList.toggle("down");
      arrow.classList.toggle("up");
    }
  };

  const handleTabIndex = () => {
    const phoneInput = document.getElementById("phone-tel-input");

    if (phoneInput) {
      phoneInput.setAttribute("tabIndex", `${open ? 0 : -1}`);
    }

    if (open) {
      setTabIndex(0);
    } else {
      setTabIndex(-1);
    }
  };

  useEffect(() => {
    getUserCountryDetails();
    getCountriesList();
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    if (!isWeb) {
      getUserAddressDetails();
    }
    if (isWeb) {
      const savedAddressDetails = localStorage.getItem("address_details");
      const shouldAutofill = JSON.parse(
        localStorage.getItem("saveAddressCheckbox")
      );
      setSaveAddress(shouldAutofill === null || shouldAutofill);
      if (savedAddressDetails && shouldAutofill !== false) {
        const parsedData = JSON.parse(savedAddressDetails);
        setAddressDetails(parsedData);
        setPhone(
          Helpers.removeNumDialCode(parsedData?.phone, countries.allCountries)
        );
        setFormData((prev) => ({
          ...prev,
          customerDetails: { ...parsedData, country },
        }));
        setSelectedCountry(parsedData.isoCode);
      }
    }
    // eslint-disable-next-line
  }, [countries]);

  useEffect(() => {
    handleTabIndex();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    checkButtonDisabled();
    // eslint-disable-next-line
  }, [addressDetails]);

  const setCheckoutAddressDetails = (countryId) => {
    // eslint-disable-next-line
    const form = document.getElementById("form");
    const formObj = {
      address: form.address.value,
      name: form.name.value,
      city: form.city.value,
      country: form.country.value,
    };
    Object.keys(addressDetails).forEach((item) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          customerDetails: {
            ...prevState.customerDetails,
            [item]: addressDetails[item],
            country,
          },
        };
      });
    });
    Object.entries(formObj).forEach((item) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          customerDetails: {
            ...prevState.customerDetails,
            [item[0]]: item[1],
            countryId,
          },
        };
      });
    });
  };

  const onCountrySelect = (num, country) => {
    if (!isWeb) {
      const dropdown = document.querySelector(".intl-tel-input");
      const countryList = document.querySelector(".country-list");
      const arrow = document.querySelector(".arrow");

      if (dropdown) {
        dropdown.classList.remove("expanded");
        countryList.classList.add("hide");
        arrow.classList.add("down");
        arrow.classList.remove("up");
      }
    }
    setAddressDetails({
      ...addressDetails,
      phone: `+${country.dialCode}${phone}`,
    });
    setSelectedCountry(country.iso2);
    setCurrentDialCode(country.dialCode);
  };

  const handleSetFormData = async () => {
    setLoading(true);
    const form = document.getElementById("form");

    const countryId = countries.availableShippingCountries.find(
      (el) => el.name === form.country.value
    )?.id;
    const countryName = countries.availableShippingCountries.find(
      (el) => el.id === countryId
    ).name;
    const shippingFee = countries.availableShippingCountries.find(
      (c) => c.name === countryName
    )?.shippingFee;

    setCheckoutAddressDetails(countryId);
    setShippingFee(shippingFee);

    if (isWeb) {
      Helpers.saveAddressToLocalStorage(saveAddress, {
        ...addressDetails,
        country: form.country.value,
        countryId,
        name: form.name.value,
        address: form.address.value,
        city: form.city.value,
        isoCode: selectedCountry,
      });
      setOpen(false);
    } else {
      await saveUserAddressDetails(countryId);
    }
    setLoading(false);
  };

  const handlePhoneChange = (isValidd, inputAsIs, iso2, international) => {
    let lettersRegExp = /[a-zA-Z]/g; //:\"\".,-:;
    // let numbersRegExp = /^[0-9]*$/g;
    if (lettersRegExp.test(inputAsIs)) {
      return;
    }
    if (inputAsIs.includes(".") || inputAsIs.includes(",")) {
      return;
    }
    if (!iso2) return;
    if (iso2 && !iso2.iso2) return;
    const dialCode = iso2.dialCode;

    let finalNumber; // eslint-disable-line

    finalNumber = Helpers.setFinalNumber(dialCode, inputAsIs);

    let dialCode2 = "972";

    if (dialCode === "970") {
      finalNumber = Helpers.setFinalNumber(dialCode2, inputAsIs);
    }

    checkButtonDisabled();
    setPhone(inputAsIs);
    setAddressDetails({
      ...addressDetails,
      phone: `+${iso2.dialCode}${inputAsIs}`,
    });
  };
  const handleSetAddressDetails = (e) => {
    const { name, value } = e?.target;

    if (name === "email") {
      setAddressDetails({ ...addressDetails, [name]: value });

      const emailRegex =
        /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      if (!emailRegex.test(value)) {
        setErrors({ ...errors, email: t("invalid_format") });
      } else {
        setErrors({ ...errors, email: "" });
      }
    }
    if (name === "country") {
      const countryName = countries.availableShippingCountries.find(
        (el) => el.id === +value?.countryId
      )?.name;
      setAddressDetails({
        ...addressDetails,
        country: countryName,
        countryName: countryName,
        countryId: value?.countryId,
      });
    } else {
      setAddressDetails({ ...addressDetails, [name]: value });
    }
  };
  const handleSetInputFocused = () => setInputBlurred(true);
  const handleSetInputBlurred = () => setInputBlurred(false);
  const toggleSaveAddress = () => {
    if (isWeb) {
      localStorage.setItem("saveAddressCheckbox", JSON.stringify(!saveAddress));
    }
    setSaveAddress((prev) => !prev);
  };
  return (
    <div
      className={`offcanvas-container ${
        open ? "offcanvas-container-block visible" : "invisible"
      }`}
    >
      {isWeb ? (
        <BackHeader
          title={t("address_details")}
          offCanvas
          closeContainer={setOpen}
        />
      ) : null}
      <DynamicScrollToTop />
      <section className="checkout-page address-page">
        <div className="checkout-section-separator" />
        <form
          id="form"
          ref={formRef}
          onSubmit={(e) => e.preventDefault()}
          className="product-details-div product-details-div-web product-details-sm"
        >
          <div className="redeem-content redeem-content-lg">
            <div>
              <Container>
                <Row>
                  <Col xs={12}>
                    <label className="vuz-label">{t("phone_number")}</label>
                    {userData?.country && (
                      <IntlTelInput
                        tabIndex={-1}
                        containerClassName={`intl-tel-input dropdown ${
                          inputBlurred ? "input-blurred" : ""
                        }`}
                        fieldName="phone"
                        defaultCountry={
                          selectedCountry ||
                          userData?.country?.toLowerCase() ||
                          "au"
                        }
                        preferredCountries={false}
                        format={false}
                        formatOnInit={false}
                        fieldId="phone-tel-input"
                        value={phone}
                        onPhoneNumberChange={handlePhoneChange}
                        separateDialCode
                        allowDropdown={false}
                        placeholder={Helpers.getCountryPlaceholder(
                          selectedCountry ? selectedCountry : userData?.country
                        )}
                        onPhoneNumberFocus={handleSetInputFocused}
                        onPhoneNumberBlur={handleSetInputBlurred}
                        onFlagClick={togglePhoneDropdown}
                        onSelectFlag={onCountrySelect}
                      />
                    )}
                  </Col>

                  <Col xs={12} className="mb-4">
                    <label className="vuz-label">{t("email")}</label>
                    <input
                      tabIndex={tabIndex}
                      name="email"
                      className={`checkout-input address-input ${
                        !!errors?.email ? "checkout-input-error" : ""
                      }`}
                      type={"email"}
                      placeholder="abc@email.com"
                      value={addressDetails?.email || ""}
                      onChange={handleSetAddressDetails}
                    />
                    {!!errors?.email && (
                      <span className="error-msg input-error">
                        {errors?.email}
                      </span>
                    )}
                  </Col>

                  <Col xs={12} className="mb-4">
                    <label className="vuz-label">{t("full_name")}</label>
                    <input
                      tabIndex={tabIndex}
                      name="name"
                      className="checkout-input address-input"
                      placeholder={t("your_full_name")}
                      defaultValue={addressDetails?.name || ""}
                      onFocus={handleInputFocus}
                      onBlur={handleInputOnBlur}
                      onChange={checkButtonDisabled}
                    />
                  </Col>

                  <Col xs={12} className="mb-4">
                    <label className="vuz-label">{t("full_address")}</label>
                    <input
                      tabIndex={tabIndex}
                      name="address"
                      className="checkout-input address-input"
                      placeholder={t("address_placeholder")}
                      defaultValue={addressDetails?.address || ""}
                      onFocus={handleInputFocus}
                      onBlur={handleInputOnBlur}
                      onChange={checkButtonDisabled}
                    />
                  </Col>
                  {(addressDetails?.countryId ||
                    preferredCountry ||
                    formData?.customerDetails?.country?.id) && (
                    <Col xs={12} className="mb-4">
                      <label className="vuz-label">{t("country")}</label>

                      <CountrySelectDropdown
                        countries={countries.availableShippingCountries || []}
                        tabIndex={tabIndex}
                        defaultCountry={{
                          countryId:
                            addressDetails?.countryId || preferredCountry?.id,
                          countryName:
                            formData.customerDetails.country ||
                            addressDetails?.countryName ||
                            preferredCountry?.name,
                        }}
                        handleSetCountry={handleSetAddressDetails}
                      />
                    </Col>
                  )}
                  {!addressDetails.countryId &&
                    !preferredCountry &&
                    !formData?.customerDetails?.country?.id &&
                    countries && (
                      <Col xs={12} className="mb-4">
                        <label className="vuz-label">{t("country")}</label>

                        <CountrySelectDropdown
                          countries={countries.availableShippingCountries || []}
                          tabIndex={tabIndex}
                          defaultCountry={{
                            countryId: "28",
                            countryName: "Australia",
                          }}
                          handleSetCountry={handleSetAddressDetails}
                        />
                      </Col>
                    )}
                </Row>
              </Container>
            </div>
          </div>
          <div  className="city-details" >
            <AddressDetailsButton
              addressDetails={addressDetails}
              handleInputFocus={handleInputFocus}
              handleInputOnBlur={handleInputOnBlur}
              formRef={formRef}
              isLoading={loading}
              tabIndex={tabIndex}
              className="confirm-btn"
              buttonDisabled={buttonDisabled}
              handleSetFormData={handleSetFormData}
              toggleSaveAddress={toggleSaveAddress}
              saveAddress={saveAddress}
            />
          </div>
        </form>
      </section>
      <CustomModal
        icon={<img alt="" src={Icons.errorIcon} width={140} />}
        description={
          <>
            <h5>{errorModal}</h5>
          </>
        }
        text={t("ok")}
        show={!!errorModal}
        onHide={() => setErrorModal(false)}
      />
    </div>
  );
};

export default AddressDetails;
