import React from 'react';
import Slider from 'react-slick';

import Helpers from '../../utils/Helpers';
import Icons from '../../constants/Icons';

import '../../assets/slick/slick.css';
import '../../assets/slick/slick-theme.css';

const PrevArrow = ({className, style, onClick}) => (
  <img
    className={`${className} carousel-button carousel-button-back`}
    src={Icons.carouselBtn}
    alt='prev'
    style={{...style, display: 'block'}}
    onClick={onClick}
  />
);

const NextArrow = ({className, style, onClick}) => (
  <img
    className={`${className} carousel-button`}
    src={Icons.carouselBtn}
    alt='next'
    style={{...style, display: 'block'}}
    onClick={onClick}
  />
);

const Carousel = ({
  ItemComponent,
  data,
  itemsToShow = 2,
  showHalfOfNext = false,
  showDots = false,
  showArrows = false,
}) => {
  const isWeb = Helpers.getIsWeb();
  const isAR = Helpers.getIsAR()
  let images = [...data]
  images = isAR ? images.reverse() : data
  const slickSettings = {
    dots: showDots,
    arrows: showArrows,
    infinite: false,
    speed: 500,
    rtl: isAR,
    slidesToShow: itemsToShow,
    slidesToScroll:itemsToShow,
    initialSlide:0,
    centerMode: showHalfOfNext,
    centerPadding: showHalfOfNext ? '7%' : '0%',
    nextArrow: showArrows ? <NextArrow/> : '',
    prevArrow: showArrows ? <PrevArrow/> : ''
  };

  return (
    <div className={`slick-outer-div ${showHalfOfNext && 'show-half-of-next'}`}>
      <div className='slick-inner-div'>
        <Slider {...slickSettings}>
          {images && images.length > 0 && images.map(dataItem => (
            <div
              className={`slick-item-div ${isWeb && 'slick-item-div-lg'}`}
              key={Math.random()}
            >
              <ItemComponent data={dataItem}/>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
