import React, { useEffect } from 'react';
import BackHeader from '../layout/BackHeader';
import DynamicScrollToTop from '../layout/DynamicScrollToTop';
import Helpers from '../../utils/Helpers';

const CustomerSupport = () => {
  const appText = Helpers.getAppText();
  const isAr = Helpers.getIsAR()
  const direction = isAr ? 'rtl' : 'ltr'

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction)
    const anchor = document.querySelector('.email-link');
    function longpress(e) {
      const url = e.target.getAttribute('href').split('mailto:')[1]
      setTimeout(() => {
        navigator.clipboard.writeText(url).then(() => {console.log(1)})
      }, 1000)
    }
    const timeout = anchor.addEventListener('mousedown', longpress)
    
    function reset() {
      clearTimeout(timeout)
    }

    anchor.addEventListener('mouseup', reset)
      return () => {
        anchor.removeEventListener('mousedwon', longpress)
        anchor.removeEventListener('mouseup', reset)
      }
  }, [direction])

  return (
    <>
      <DynamicScrollToTop />
      <BackHeader title={appText.cs} />
      <section className='customer-support-section handle-fixed-nav'>
        <div className='cs-div'>
          <p className='cs-header'>{appText.cs_1}</p>
          <p className='cs-text'>
          {appText.cs_2}{' '}
          <span>
            <a href='mailto:support@360mea.com' className='email-link'>
              support@360mea.com
            </a>
            </span>
            .
          </p>
          <p className='cs-text'>
          {appText.cs_3}
          </p>
        </div>
      </section>
    </>
  );
};

export default CustomerSupport;
