import React from "react"

const DropdownPolygon = ({className}) => {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M4.37191 4.58709C4.32503 4.63914 4.26773 4.68075 4.20373 4.70924C4.13973 4.73772 4.07046 4.75244 4.00041 4.75244C3.93036 4.75244 3.86109 4.73772 3.79709 4.70924C3.73309 4.68075 3.6758 4.63914 3.62891 4.58709L0.250912 0.834087C0.186389 0.762208 0.144067 0.673179 0.129062 0.577761C0.114057 0.482343 0.12701 0.384622 0.166357 0.296409C0.205704 0.208196 0.26976 0.133267 0.350781 0.0806813C0.431803 0.0280952 0.526321 0.000102997 0.622911 8.72612e-05L7.37691 8.72612e-05C7.4735 0.000102997 7.56802 0.0280952 7.64904 0.0806813C7.73006 0.133267 7.79412 0.208196 7.83347 0.296409C7.87281 0.384622 7.88577 0.482343 7.87076 0.577761C7.85576 0.673179 7.81343 0.762208 7.74891 0.834087L4.37191 4.58709Z" fill="#00AAFF"/>
    </svg>
  )
}

export default DropdownPolygon