import React from "react";
import { useTranslation } from "react-i18next";
import Icons from "../../constants/Icons";

export default function BuyPoints({ url }) {
  const { t } = useTranslation();

  return (
    <a href={url} className="buy-points-btn-div">
      <img src={Icons.diamonds} className="diamonds-img" alt="diamonds" />
      <div className="buy-points-text-div">{t("buy_points")}</div>
    </a>
  );
}
