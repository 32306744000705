import React from 'react';

import Helpers from '../../utils/Helpers';

const WebContainer = ({ children }) => {
  const isWeb = Helpers.getIsWeb();

  return (
    <div>
      <div className={`${isWeb && 'page-section-web'}`}>
        {children}
      </div>
    </div>
  );
};

export default WebContainer;
