import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Helpers from "../../utils/Helpers";
import Icons from "../../constants/Icons";

const ProductImage = ({
  productDetails,
  isUserVip,
  handleRedeem,
  selectedSize,
  productId,
  handleBuy,
}) => {
  const { t } = useTranslation();
  const isWeb = Helpers.getIsWeb();
  const isTablet = Helpers.getIsTablet();
  const isMobile = Helpers.getIsMobile();
  const appText = Helpers.getAppText();
  const isAR = Helpers.getIsAR();
  const userToken = Helpers.getUserToken() || "";
  const { isRedeemable, isPurchasable } = useMemo(() => {
    return Helpers.getBillingOptions(productDetails);
  }, [productDetails]);

  const originPath = window.location.origin;
  const finalProductId = selectedSize?.id ? selectedSize.id : productId;

  return (
    <div
      style={
        isWeb && (isMobile || isTablet)
          ? {
              maxWidth: "none",
              backgroundColor: "transparent",
              border: "none",
              width: "97%",
            }
          : isWeb
          ? {
              position: "absolute",
              maxWidth: "329px",
            }
          : null
      }
      className={`${
        !isTablet && !isMobile
          ? "product-details-actions-div-lg"
          : "product-details-actions-div"
      }`}
    >
      {isRedeemable && isPurchasable ? (
        <>
          <div
            style={{ color: "white" }}
            className={`confirm-btn-pad1 pos-rel mt-2 ${
              isWeb ? "w-100" : "w-50"
            }`}
          >
            {isUserVip && (
              <img
                src={Icons.vipDiscountSm}
                alt="VIP"
                className="vip-product vip-product-sm"
              />
            )}
            <a
              onClick={handleBuy}
              style={
                !isPurchasable || !productDetails.stock
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : null
              }
              className={`confirm-btn ${!isWeb ? "confirm-btn-mobile" : ""}`}
              href={
                !userToken && !isWeb
                  ? `${originPath}/shop/dev/orders?navigation=authentication`
                  : `/shop/products/${productId}/buy?payment=card&productId=${finalProductId}`
              }
            >
              {isWeb ? t("buy_now") : appText.buy_now}
            </a>
          </div>
          {!isWeb && (
            <div
              style={{ color: "#00AAFF" }}
              className={`confirm-btn-pad2 mt-2 pos-rel w-50 ${
                isAR ? "mr-3" : "ml-3"
              }`}
            >
              <a
                href={
                  !userToken
                    ? `${originPath}/shop/dev/orders?navigation=authentication`
                    : `/shop/products/${productId}/buy?payment=points&productId=${finalProductId}`
                }
                className={`confirm-btn grey ${isAR ? "confirm-btn-ar" : ""} ${
                  !isWeb ? "confirm-btn-mobile" : ""
                }`}
                style={
                  productDetails.stock <= 0
                    ? { pointerEvents: "none", opacity: "0.5" }
                    : null
                }
                onClick={handleRedeem}
              >
                {appText.redeem_with_points}
              </a>
            </div>
          )}
        </>
      ) : (
        <>
          {isRedeemable && !isWeb ? (
            <div
              style={{ color: "#00AAFF" }}
              className="confirm-btn-pad1 w-100 pos-rel"
            >
              <a
                href={
                  !userToken
                    ? `${originPath}/shop/dev/orders?navigation=authentication`
                    : `/shop/products/${productId}/buy?payment=points&productId=${finalProductId}`
                }
                className={`confirm-btn ${!isWeb ? "confirm-btn-mobile" : ""}`}
                onClick={handleRedeem}
                style={
                  !productDetails.stock
                    ? { pointerEvents: "none", opacity: "0.5" }
                    : null
                }
              >
                {appText.redeem_with_points}
              </a>
            </div>
          ) : (
            <div className="w-100">
              <div
                style={{ color: "white" }}
                className="confirm-btn-pad2 w-100 pos-rel"
              >
                {isUserVip && (
                  <img
                    src={Icons.vipDiscountLg}
                    alt="VIP"
                    className="vip-product vip-product-lg"
                  />
                )}
                <a
                  onClick={handleBuy}
                  style={
                    !isPurchasable || !productDetails.stock
                      ? { pointerEvents: "none", opacity: "0.5" }
                      : null
                  }
                  className={`confirm-btn ${
                    !isWeb ? "confirm-btn-mobile" : ""
                  }`}
                  href={
                    !userToken && !isWeb
                      ? `${originPath}/shop/dev/orders?navigation=authentication`
                      : `/shop/products/${productId}/buy?payment=card&productId=${finalProductId}`
                  }
                >
                  {isWeb ? t("buy_now") : appText.buy_now}
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductImage;
