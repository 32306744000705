import Arabic from '../constants/Arabic';
import English from '../constants/English';
import countryCodes from '../constants/CountryCodes';
import finalNumbers from '../constants/FinalNumbers';
import { amplitude } from '../amplitude';

const Helpers = {
  getAxiosHeadersConfig: () => {
    const qLanguage = Helpers.getUrlParameter('language') || localStorage.getItem('language') || 'en';
    let defaultDeviceId = 'CBC64810-FF0C-4008-9558-A999C18085E7';
    const qDeviceId = Helpers.getUrlParameter('deviceId') || localStorage.getItem('deviceId') || defaultDeviceId;
    const qUserToken = Helpers.getUrlParameter('userToken') || localStorage.getItem('userToken')|| '';
    const qPlatform = Helpers.getUrlParameter('platform') || localStorage.getItem('platform') || 'android';
    const qApiVersion = Helpers.getUrlParameter('appVersion') || '4.11.1';

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-api-version': qApiVersion,
        'x-api-key': process.env.REACT_APP_API_KEY,
        'x-api-os': qPlatform,
        'x-user-lang': qLanguage,
        'x-api-device-id': qDeviceId,
        'authorization': qUserToken,
      }
    };

    return config;
  },

  getIsWeb: () => {
    const qPlatform = Helpers.getUrlParameter('platform') || localStorage.getItem('platform');

    return !qPlatform;
  },
  getIsAndroid: () => {
    const isAndroid = Helpers.getUrlParameter('platform') === 'android' || localStorage.getItem('platform') === 'android'

    return isAndroid
  },

  getIsAR: () => {
    const lng = Helpers.getUrlParameter('language') || localStorage.getItem('language')

    return lng === 'ar'
  },

  getIsIos: () => {
    const isIos = Helpers.getUrlParameter('platform') === 'ios' || localStorage.getItem('platform') === 'ios'

    return isIos
  },

  getIsMobile: () => {
    return window.innerWidth <= 414;
  },
  getIsTablet: () => {
    return window.innerWidth > 414 && window.innerWidth <= 768;
  },
  getCurrentPath: () => {
    let currentPath = window.location.href;
    let currentPathLength = currentPath.length;
    if (currentPath.charAt(currentPathLength - 1) !== '/') {
      currentPath = currentPath + '/';
    }
    return currentPath;
  },
  getUrlParameter: (name) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  getBillingOptions: (productDetails) => {
	const isRedeemable = productDetails?.billingOptions?.some(
		(el) => (el.method === 'points')
	);
	const isPurchasable = productDetails?.billingOptions?.some(
		(el) => (el.method === 'creditCard')
	);

	return {isRedeemable, isPurchasable}
},
  getUserToken: () => {
    const qUserToken = Helpers.getUrlParameter('userToken');
    const lsUserToken = localStorage.getItem('userToken');
    let userToken = null;

    if (qUserToken) {
      userToken = qUserToken;
      localStorage.setItem('userToken', userToken);
    } else if (lsUserToken) {
      userToken = lsUserToken;
    }
    return userToken
  },
  
  getAppParameters: () => {
    const qUserToken = Helpers.getUrlParameter('userToken') || localStorage.getItem('userToken');

    const qLanguage = Helpers.getUrlParameter('language');
    const lsLanguage = localStorage.getItem('language');
    let language = null;

    if (qLanguage) {
      language = qLanguage;
      localStorage.setItem('language', language);
    } else if (lsLanguage) {
      language = lsLanguage;
    }

    const qPlatform = Helpers.getUrlParameter('platform');
    const lsPlatform = localStorage.getItem('platform');
    let platform = null;

    if (qPlatform) {
      platform = qPlatform;
      localStorage.setItem('platform', platform);
    } else if (lsPlatform) {
      platform = lsPlatform;
    }

    const qAppVersion = Helpers.getUrlParameter('appVersion'); 
    const lsAppVersion = localStorage.getItem('appVersion');
    let appVersion;

    if (qAppVersion) {
      appVersion = qAppVersion;
      localStorage.setItem('appVersion', appVersion);
    } else if (lsAppVersion) {
      appVersion = lsAppVersion;
    }

    const qDeviceId = Helpers.getUrlParameter('deviceId')
    if (qDeviceId){
      localStorage.setItem('deviceId', qDeviceId)
    }

    const qShopId = Helpers.getUrlParameter('shopId')
    if (qDeviceId){
      localStorage.setItem('shopId', qShopId)
    }
    return {
      qUserToken,
      language,
      platform,
      appVersion
    };
  },

  getAppVersion: () => {
    const appVersion = Helpers.getUrlParameter('appVersion') || localStorage.getItem('appVersion')
  
    if (appVersion) {
      return appVersion
    }
    return null
  },

  setAppLanguage: (i18n) => {
    const qLanguage = Helpers.getUrlParameter('language') || '';
    const lsLanguage = localStorage.getItem('language');
    const i18Language = localStorage.getItem('i18nextLng');
    let needToSetLanguageToEn = false;

    if ((!qLanguage && !lsLanguage) || qLanguage === 'en' || i18Language === 'en') {
      if (lsLanguage === 'en' || qLanguage === 'en') {
        i18n.changeLanguage('en');
        needToSetLanguageToEn = true;
      }
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
      localStorage.setItem('i18nextLng', 'en');
    }

    if (
      (qLanguage === 'ar' && i18Language === 'ar') ||
      ((lsLanguage === 'ar' || i18Language === 'ar') && !needToSetLanguageToEn)
    ) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      localStorage.setItem('language', 'ar');
      i18n.changeLanguage('ar');
      localStorage.setItem('i18nextLng', 'ar');
    }
  },

  setDirection: () => {
    const lang = localStorage.getItem('language');

    if (lang === 'ar') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
    }
  },

  getCountryPlaceholder: (countryCode, lang = '') => {
    let placeholder = '05XXXXXXXX';

    countryCodes.forEach((item) => {
      if (item.countryCode && (countryCode === item.countryCode || countryCode === item.countryCode.toUpperCase())) {
        placeholder = item.placeholder;
      } else if (item.lang && lang === item.lang) {
        placeholder = item.placeholder;
      }
    });

    return placeholder;
  },

  setFinalNumber: (code, inputAsIs) => {
    let finalNumber = inputAsIs;

    finalNumbers(code).forEach((item) => {
      if (finalNumber.startsWith(item.starts)) {
        finalNumber = finalNumber.replace(item.replace, item.replaceWith);
      }
    });

    return finalNumber;
  },

  removeNumDialCode : (phoneNum = '', countries = []) => {
    try {
      let numWithoutSpaces = phoneNum.replace(/ /g, '')
      const country = countries.find( c => numWithoutSpaces.includes(c.dialCode))
      const finalNumber = numWithoutSpaces.replace(country?.dialCode, '')
      return finalNumber
    } catch (error) {
      return error
    }
  },

  mapOrder: (array, order, key) => {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  },

  getAppText: () => {
    const Qlanguage = Helpers.getUrlParameter('language') || localStorage.getItem('language') || 'en';
    if (Qlanguage === 'ar') {
      return Arabic;
    } else {
      return English;
    }
  },

  runTrackEvents: (name, data) => {
    window.trackEvent(name, data);

    amplitude.getInstance().logEvent(name, data);

    if (window.Insider) {
      window.Insider.track('events', [{
        event_name: name,
        event_params: data
      }])
    }
  },

  getUniqueListBy: (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  },

  saveAddressToLocalStorage: (saveAddress, addressDetails) => {
    if (saveAddress) {
      localStorage.setItem("address_details", JSON.stringify(addressDetails));
    } else {
      localStorage.removeItem("address_details");
    }
  }
};

export default Helpers;
