import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from '../../constants/Icons';

const CustomerSupportButton = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={`customer-support-button ${open ? 'customer-support-open' : ''}`}
        onClick={!open ? handleOpen : null}
      >
        <div className='button'>
          <div>
            <img
              src={Icons.support}
              className='support-img'
              alt='Customer support'
            />
            <span>{t('support')}</span>
          </div>
          {open && (
            <img
              className='close-icon'
              src={Icons.closeBtn}
              alt=''
              onClick={handleClose}
            />
          )}
        </div>

        {open && (
          <div className='support-content'>
            <span>{t('for_inquires')}</span>
            <span className='send-email'>
                {t('send_an_email')}
              <a href='mailto:shop@360mea.com'>shop@360mea.com</a>
              </span>
            <span>
                {t('reply_text')}
              </span>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerSupportButton;
