import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import CommonHeader from "./CommonHeader";

import Helpers from "../../utils/Helpers";
import ShopLogo from "../../assets/images/shop_logo_web.png";
import Icons from "../../constants/Icons";
import BuyPoints from "../shop/BuyPoints";

const Header = ({ loadingPage }) => {
  const userToken = Helpers.getUserToken() || "";
  const appText = Helpers.getAppText();
  const isIos = Helpers.getIsIos();

  const originPath = window.location.origin;

  const [menuVisible, setMenuVisible] = useState(false);
  const [buyPointsVisible, setBuyPointsVisible] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = () => {
    setMenuVisible((menuVisible) => !menuVisible);
  };

  const handleLogoClick = () => {
    navigate(`/shop?userToken=${userToken}`);
  };

  const handleOrdersClick = () => {
    if (userToken) {
      navigate(`/shop/orders`);
    } else {
      navigate(`/shop/orders/?navigation=authentication`);
    }
  };

  const handleCustomerSupportClick = () => {
    navigate(`/shop/customer-support`);
  };

  useEffect(() => {
    if (!loadingPage) {
      setTimeout(() => {
        setBuyPointsVisible(true);
      }, 2500);
    }
  }, [loadingPage]);

  return (
    <section className="header-section">
      <CommonHeader />
      <Container>
        <Row>
          <Col>
            <div className="buy-points-div">
              {buyPointsVisible && (
                <BuyPoints
                  url={
                    "/shop?navigation=buyPointsDrawer" +
                    "&userToken=" +
                    userToken
                  }
                />
              )}
            </div>
          </Col>
          <Col>
            <div onClick={handleLogoClick} className="text-center">
              <img src={ShopLogo} className="logo-img" alt="" />
            </div>
          </Col>
          <Col>
            <div className="menu-div">
              <img
                src={Icons.menu}
                className="menu-img"
                alt=""
                onClick={handleMenuClick}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={menuVisible} onHide={handleMenuClick} className="menu-modal">
        <Modal.Body>
          <div
            style={{ paddingBottom: isIos ? "140px" : "30px" }}
            className="menu-body"
          >
            {userToken ? (
              <div className="menu-row" onClick={handleOrdersClick}>
                <img src={Icons.orders} className="menu-item-img" alt="" />
                <span className="menu-item-name">{appText.orders}</span>
              </div>
            ) : (
              <a
                href={`${originPath}/shop/orders/?navigation=authentication`}
                className="menu-row"
              >
                <img src={Icons.orders} className="menu-item-img" alt="" />
                <span className="menu-item-name">{appText.orders}</span>
              </a>
            )}
            <br />

            <div className="menu-row" onClick={handleCustomerSupportClick}>
              <img src={Icons.support} className="menu-item-img" alt="" />
              <span className="menu-item-name">{appText.cs}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Header;
