import React, { useEffect, useState } from "react";
import { BrowserRouter, } from "react-router-dom";
import phone from "./assets/images/ic_newupdate.png";
//import { Elements } from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import "./styles/AppResponsive.css";
import "./styles/AppRtl.css";

import Routes from "./components/routing/Routes";
import Helpers from "./utils/Helpers";
import CustomModal from "./components/shop/Modal";
import { useTranslation } from "react-i18next";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const isWeb = Helpers.getIsWeb();
  const isAndroid = Helpers.getIsAndroid();
  const isIos = Helpers.getIsIos();
  const { t } = useTranslation();
  const appVersion = Helpers.getAppVersion();
  const [newUpdateModalOpen, setNewUpdateModalOpen] = useState({
    openedOnce: false,
    opened: false,
  });
  const platform =
    Helpers.getUrlParameter("platform") || localStorage.getItem("platform");

  useEffect(() => {
    if (isWeb) {
      document.body.classList.add("lg");
    }
  }, [isWeb]);

  useEffect(() => {
    Helpers.getAppParameters();
    Helpers.getUserToken();
    if ((isIos && appVersion) || isWeb || isAndroid) {
      // injects useInsider iframe (3rd party iframes are allowed for new app versions (IOS))
      const script = document.createElement("script");
      script.src = "//360vuz.api.useinsider.com/ins.js?id=10004229";
      script.async = true;
      document.body.appendChild(script);
    }
    if (platform === "ios" && !appVersion && !newUpdateModalOpen.openedOnce) {
      setNewUpdateModalOpen({ openedOnce: true, opened: true });
    }

    if (isIos) {
      const meta = document.querySelector('meta[name="viewport"]');
      const content = meta.getAttribute("content");
      const newContent = content + ", maximum-scale=1, user-scalable=0";
      meta.setAttribute("content", newContent);
    }
  }, []);

  const newUpdateModalBody = (
    <>
      <p className="new-update-header">{t("new_update_header")}</p>
      <p>{t("new_update_text")}</p>
    </>
  );

  return (
    <>
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
      {newUpdateModalOpen.opened ? (
        <CustomModal
          onClick={(e) => e.stopPropagation()}
          icon={<img alt="Phone" src={phone} width={112} height={129} />}
          description={newUpdateModalBody}
          show={newUpdateModalOpen.opened}
        />
      ) : null}
    </>
  );
}

export default App;
