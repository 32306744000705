
const Arabic = {
  view_all: 'اعرضهم جميعا',
  size: 'حجم',
  description: 'وصف',
  orders: 'طلباتي',
  cs: 'دعم العملاء',
  cs_1: 'لأي إستفسار أو سؤال أو ملاحظة:',
  cs_2: 'لا تتردد في إرسال بريد إلكتروني إلينا على',
  cs_3: 'سيقوم فريق الدعم الخاص بنا بالرد عليك وسيزودك بجميع المعلومات والمساعدة التي تحتاجها في أقرب وقت ممكن.',
  redeem_with_points: 'استبدال بالنقاط',
  buy_now: 'اشتري الآن',
  left: 'متبقية',
  points: 'نقاط',
  merch: 'متجر',
  view: 'عرض',
  no_orders: 'لا توجد أي طلبات',
  bought_on: 'تم شراءه بتاريخ',
  product_details: 'تفاصيل المنتج',
  shop: 'متجر',
  buy_points: 'اشحن نقاطك',
  day: 'يوم',
  day_left: 'يوم متبقي',
  days_left: 'أيام متبقية'
};

export default Arabic;
