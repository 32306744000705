const countryCodes = [
  {
    countryCode: 'ae',
    placeholder: '05XXXXXXXX'
  },
  {
    countryCode: 'eg',
    placeholder: '01XXXXXXXXX'
  },
  {
    countryCode: 'bh',
    placeholder: '36XXXXXX'
  },
  {
    countryCode: 'qa',
    placeholder: '33XXXXXX'
  },
  {
    countryCode: 'jo',
    placeholder: '07XXXXXXXX'
  },
  {
    countryCode: 'tn',
    placeholder: '20XXXXXX'
  },
  {
    countryCode: 'iq',
    placeholder: '07XXXXXXXXX'
  },
  {
    countryCode: 'ps',
    placeholder: '05XXXXXXXX'
  },
  {
    countryCode: 'il',
    placeholder: '05XXXXXXXX'
  },
  {
    countryCode: 'sa',
    placeholder: '05XXXXXXXX'
  },
  {
    countryCode: 'kw',
    placeholder: '500XXXXX'
  },
  {
    countryCode: 'za',
    placeholder: '071XXXXXXX'
  },
  {
    countryCode: 'es',
    placeholder: '612XXXXXX'
  },
  {
    countryCode: 'ca',
    placeholder: '506XXXXXXX'
  },
  {
    countryCode: 'uk',
    placeholder: '07400XXXXXX'
  },
  {
    countryCode: 'id',
    placeholder: '08XXXXXXXX'
  },
  {
    countryCode: 'my',
    placeholder: '01XXXXXXXX'
  },
  {
    countryCode: 'pk',
    placeholder: '03XXXXXXXXX'
  },
  {
    lang: 'en',
    placeholder: 'Mobile number'
  },
  {
    lang: 'ar',
    placeholder: 'رقم الجوال'
  }
]

export default countryCodes;
