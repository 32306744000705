import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Helpers from '../../utils/Helpers';

const CommonHeader = () => {
  const { i18n } = useTranslation();

  const qLanguage = Helpers.getUrlParameter('language');
  const lLanguage = localStorage.getItem('language')
  const userToken = Helpers.getUserToken() || '';
  const platform = Helpers.getUrlParameter('platform');
  const deviceId = Helpers.getUrlParameter('deviceId');

  useEffect(() => {
    if ((qLanguage && qLanguage === 'ar') || (lLanguage && lLanguage === 'ar')) {
      i18n.changeLanguage('ar');
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    } else {
      i18n.changeLanguage('en');
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
  }, [i18n,qLanguage, lLanguage]);

  if (platform) {
    localStorage.setItem('platform', platform);
  }

  if (deviceId) {
    localStorage.setItem('deviceId', deviceId);
  }

  const getUser = async () => {
    const getUserUrl = `${process.env.REACT_APP_API_URL}/subscribers/me/profile`;
    const config = Helpers.getAxiosHeadersConfig();

    try {
      const res = await axios.get(getUserUrl, config);
      let { data: userData, status, message } = res.data;

      if (status === 'success') {
        window.analyticsUser = userData;
        window.setAnalyticsUser(userData);
      } else {
        console.log(message);
      }
    } catch (err) {
      console.log('getUserResponseError', err);
    }
  };

  useEffect(() => {
    if (userToken && !window.analyticsUser) {
      // get user info from endpoint and call window.analyticsUser(userId)
      getUser();
    }
  }, [userToken]);

  return <></>;
};

export default CommonHeader;
