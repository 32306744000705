import React from "react";
import { useNavigate } from "react-router-dom";
import Helpers from "../../utils/Helpers";

const ShopItem = ({ data: shop }) => {
  const isWeb = Helpers.getIsWeb();
  const navigate = useNavigate();

  const onClickShopItem = (selectedShop) => {
    navigate(
      `/shop/sections/${selectedShop.id}?title=${selectedShop.title}&type=3${
        isWeb ? "&source=web" : ""
      }`
    );
  };

  return (
    <div className="shop-item-div" onClick={() => onClickShopItem(shop)}>
      <div className="shop-img-container">
        <img
          className="shop-img"
          src={shop.thumbnail}
          alt={shop.title || "shop"}
        />
      </div>
      <p className="shop-name">{shop.title}</p>
    </div>
  );
};

export default ShopItem;
