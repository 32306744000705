import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Icons from "../../constants/Icons";
import { useTranslation } from "react-i18next";
import BuyPoints from "./BuyPoints";
import Helpers from "../../utils/Helpers";

const PointsStatus = ({ pointsBalance = 0 }) => {
  const { t } = useTranslation();
  const url = window.location.pathname;
  const productId = Helpers.getUrlParameter("productId");
  const payment = Helpers.getUrlParameter("payment");

  return (
    <Container className="pt-2">
      <Row>
        <Col xs={12} className="form-title">
          {t("points_status")}
        </Col>
      </Row>
      <Row className="points-status-container">
        <Col xs={6} className="points-info-container">
          <div className="point-image-container">
            <img src={Icons.diamond} className="diamond-img2" alt="diamond" />
          </div>
          <div className="points-count-container">
            <span className="points-count-title">{t("your_points")}</span>
            <span className="points-count">{pointsBalance}</span>
          </div>
        </Col>
        <Col xs={6} className="buy-points-container">
          <BuyPoints
            url={`${url}?payment=${payment}&productId=${productId}&navigation=buyPointsDrawer`}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PointsStatus;
