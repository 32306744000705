import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CommonHeader from './CommonHeader';
import LanguageDropdown from '../shop/LanguageDropdown';

import ShopLogo from '../../assets/images/shop_logo_web.png';
import Helpers from '../../utils/Helpers';

const HeaderWeb = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    Helpers.setAppLanguage(i18n);
  }, [i18n])

  return (
    <section className='header-section header-section-lg'>
      <CommonHeader/>
      <div className='web-header'>
        <Row>
          <Col>
            <div className='text-center logo'>
              <a
                href={`/shop`}
                className='logo-container'
              >
                <img
                  src={ShopLogo}
                  className='logo-img logo-img-web'
                  alt='360VUZ'
                />
              </a>
            </div>
          </Col>
          <Col>
            <div className='menu'>
              <LanguageDropdown />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HeaderWeb;
