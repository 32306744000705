const finalNumbers = (code) => [
  {
    starts: `00${code}0`,
    replace: `00${code}0`,
    replaceWith: `+${code}`
  },
  {
    starts: `00${code}`,
    replace: `00${code}`,
    replaceWith: `+${code}`
  },
  {
    starts: `0${code}0`,
    replace: `0${code}0`,
    replaceWith: `+${code}`
  },
  {
    starts: `0${code}`,
    replace: `0${code}`,
    replaceWith: `+${code}`
  },
  {
    starts: `+${code}0`,
    replace: `+${code}0`,
    replaceWith: `+${code}`
  },
  {
    starts: `+${code}`,
    replace: `+${code}`,
    replaceWith: `+${code}`
  },
  {
    starts: `${code}0`,
    replace: `${code}0`,
    replaceWith: `+${code}`
  },
  {
    starts: `${code}`,
    replace: `${code}`,
    replaceWith: `+${code}`
  },
  {
    starts: `0`,
    replace: `0`,
    replaceWith: ``
  }
]

export default finalNumbers;
