import React from "react";
import { useTranslation } from "react-i18next";
import Icons from "../../constants/Icons";

const TotalPrice = ({
  productDetails,
  shippingFee,
  isUserVip,
  count = 1,
  showShippingCost = false,
  paymentOption = "card",
}) => {
  const { t } = useTranslation();
  const fee = shippingFee || 0;
  const vipDiscount = 0.1;
  const subtotal = parseFloat(productDetails.priceMoney) * count;
  const totalCost = isUserVip
    ? (subtotal + (fee || 0)) * (1 - vipDiscount)
    : subtotal + (fee || 0);
  const roundedTotalCost = (Math.round(totalCost * 100) / 100).toFixed(2);

  if (paymentOption !== "points") {
    return (
      <div className="total-prices-container">
        <div>
          <span className="price-title-sm">{t("subtotal")}</span>
          <span className="price-sm">${subtotal}</span>
        </div>
        {(fee || fee === 0) && showShippingCost ? (
          <div>
            <span className="price-title-sm">{t("shipping_fee")}</span>
            <span className="price-sm">${fee}</span>
          </div>
        ) : null}
        {isUserVip ? (
          <div className="vip-discount-container">
            <span className="price-title-sm"> VIP </span>
            <span className="price-sm" dir="ltr">
              {" "}
              -10%{" "}
            </span>
          </div>
        ) : null}
        {showShippingCost ? (
          <div>
            <span className="price-title">{t("total_cost")}</span>
            <span className="price">${roundedTotalCost}</span>
          </div>
        ) : null}
      </div>
    );
  }

  if (paymentOption === "points") {
    return (
      <div className="total-prices-container">
        <div>
          <span className="price-title">{t("total_cost")}</span>
          <div>
            <img src={Icons.diamond} className="diamond-img2" alt="diamond" />
            <span className="price points">{productDetails.price}</span>
          </div>
        </div>
      </div>
    );
  }
};

export default TotalPrice;
