import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

import BackHeader from "../layout/BackHeader";
import DynamicScrollToTop from "../layout/DynamicScrollToTop";
import Helpers from "../../utils/Helpers";
import Icons from "../../constants/Icons";
import { useTranslation } from "react-i18next";

const OrdersManagement = () => {
  const appText = Helpers.getAppText();
  const userToken = Helpers.getUserToken() || "";
  const currentPath = window.location.href;
  const isAR = Helpers.getIsAR();
  const { t } = useTranslation();
  const direction = isAR ? "rtl" : "ltr";
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  if (!userToken && !currentPath.includes("authentication")) {
    // window.location.href = `${originPath}/shop/orders?navigation=authentication`;
    // window.location.href = `${originPath}/shop?navigation=authentication`;
  }
  // else if (currentPath.includes('authentication') && !userToken) {
  //   window.history.back();
  //   // window.history.go(-2);
  // }

  const getOrders = async () => {
    setOrdersLoading(true);
    const config = Helpers.getAxiosHeadersConfig();
    const getOrdersUrl = `${process.env.REACT_APP_API_URL}/shopv2/orders`;

    try {
      const res = await axios.get(getOrdersUrl, config);
      let { data: ordersData, status, message } = res.data;

      if (status === "success") {
        setOrders(ordersData);
      } else {
        console.log(message);
      }

      setOrdersLoading(false);
    } catch (err) {
      setOrdersLoading(false);
      console.log("getOrdersResponseError", err);
    }
  };

  useEffect(() => {
    document.documentElement.setAttribute("dir", direction);
    getOrders();
  }, [direction]);
  console.log(orders);
  return (
    <>
      <DynamicScrollToTop />
      <BackHeader title={appText.orders} />
      <section dir={direction} className="orders-section handle-fixed-nav">
        {ordersLoading && <div className="loader mt-5" />}

        {!ordersLoading && orders && orders.length === 0 && (
          <div className="text-center">
            <img src={Icons.noOrderss} className="no-orders-img" alt="" />
            <p className="no-orders-text">{appText.no_orders}</p>
          </div>
        )}

        {!ordersLoading &&
          orders &&
          orders.length > 0 &&
          orders.map((orderItem) => (
            <div className="order-card" key={orderItem.id}>
              <p className="order-date">
                {t("bought_on")}
                {` ${orderItem.date.split("-")[2]}-${
                  orderItem.date.split("-")[1]
                }-${orderItem.date.split("-")[0]}`}
              </p>
              <div className="order-content">
                <Container>
                  <Row>
                    <Col xs={3} className="px-2">
                      <div className="text-center">
                        <img
                          src={orderItem.productDetails.backgroundImageURL}
                          className="order-product-img"
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xs={7} className="px-2">
                      <div className="order-product-div1">
                        <p className="order-product-name">
                          {orderItem.productDetails.name}
                        </p>
                      </div>
                      {!isAR ? (
                        <div className="order-product-div2">
                          {orderItem.productDetails.attributes &&
                            orderItem.productDetails.attributes.size && (
                              <span className="order-size-span">
                                {orderItem.productDetails.attributes.size.toUpperCase()}
                              </span>
                            )}
                          {orderItem.method === "points" ? (
                            <span className="order-price">
                              <img
                                src={Icons.diamond}
                                className="order-diamond-img"
                                alt=""
                              />
                              {orderItem.cost}
                            </span>
                          ) : (
                            <span className="order-price">
                              ${orderItem.cost}
                            </span>
                          )}

                          {orderItem.isVip && (
                            <span className="vip-badge-order">
                              <img
                                src={Icons.crown}
                                alt="vip"
                                className="vip-badge-img-order"
                              />
                              VIP
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="order-product-div2">
                          {orderItem.method === "points" ? (
                            <span className="order-price mr-1">
                              <img
                                src={Icons.diamond}
                                className="order-diamond-img"
                                alt=""
                              />
                              {orderItem.cost}
                            </span>
                          ) : (
                            <span className="order-price mr-1">
                              ${orderItem.cost}
                            </span>
                          )}

                          {orderItem.isVip && (
                            <span className="vip-badge-order">
                              <img
                                src={Icons.crown}
                                alt="vip"
                                className="vip-badge-img-order"
                              />
                              VIP
                            </span>
                          )}
                          {orderItem.productDetails.attributes &&
                            orderItem.productDetails.attributes.size && (
                              <span className="order-size-span">
                                {orderItem.productDetails.attributes.size.toUpperCase()}
                              </span>
                            )}
                        </div>
                      )}
                      <div className="order-product-div3">
                        <span
                          className={`order-badge ${
                            orderItem.status === "placed" && "blue"
                          } ${orderItem.status === "shipped" && "red"} ${
                            orderItem.status === "delivered" && "green"
                          } ${orderItem.status === "Cancelled" && "red"}`}
                        >
                          {t(orderItem.status)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          ))}
      </section>
    </>
  );
};

export default OrdersManagement;
