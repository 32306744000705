import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Header from "../layout/Header";
import Banner from "../shop/Banner";
import FreeCarousel from "../shop/FreeCarousel";
import ShopItem from "../shop/ShopItem";
import ProductItem from "../shop/ProductItem";
import HeaderWeb from "../layout/HeaderWeb";
import Helpers from "../../utils/Helpers";
import CustomerSupportButton from "../shop/CustomerSupportButton";

const Home = () => {
	const { t, i18n } = useTranslation();
	const shopId = Helpers.getUrlParameter("shopId") || "";
	const productId = Helpers.getUrlParameter("productId") || "";
	const {userToken} = Helpers.getAppParameters() || "";
	const i18nLang = localStorage.getItem("i18nextLng");
	const lang = Helpers.getUrlParameter('language') || localStorage.getItem("language") || 'en';
	const isWeb = Helpers.getIsWeb();
	const isMobile = Helpers.getIsMobile();

	const [loadingPage, setLoadingPage] = useState(false);
	const [sectionsOrder, setSectionsOrder] = useState([]);
	const [sectionsData, setSectionsData] = useState([]);
	const [sectionsDataSorted, setSectionsDataSorted] = useState([]);
	const [numberOfSections, setNumberOfSections] = useState(0);
	const navigate = useNavigate()
	
	if (shopId && shopId !== "" && shopId.length > 0) {
		navigate(`/shop/sections/${shopId}?from=home&deeplink=true`);
	}

	if (productId && productId !== "" && productId.length > 0) {
		navigate(`/shop/products/${productId}?from=home&deeplink=true`);
	}

	const handleViewSection = (sectionItem) => {
		Helpers.runTrackEvents("product_section_clicked", {
			source: "shop",
			section_id: sectionItem.id,
			section_name: sectionItem.title,
			is_guest: !userToken,
		});

		navigate(`/shop/sections/${sectionItem.id}?title=${sectionItem.title}&type=${sectionItem.itemType}&from=home`);
	};

	useEffect(() => {
		if (isWeb) {
			if (lang !== i18nLang) {
				i18n.changeLanguage(lang);
				Helpers.setDirection();
			} else {
				generateHomePage();
			}
		}
	}, [isWeb, i18nLang, lang]);

	useEffect(() => {
		if (!isWeb) {
			Helpers.setAppLanguage(i18n);
			generateHomePage();
		}
	}, [lang, i18n]);

	useEffect(() => {
		if (
			sectionsData.length === numberOfSections &&
			numberOfSections > 0 &&
			sectionsDataSorted.length === 0
		) {
			const sortedSectionsData = Helpers.mapOrder(
				[...sectionsData],
				sectionsOrder,
				"id"
			);
			setSectionsDataSorted(sortedSectionsData);
			setLoadingPage(false);

			// localStorage.setItem('homeData', {
			//   lastFetched: Date.now(),
			//   data: sortedSectionsData
			// });
		}
	}, [sectionsData, numberOfSections]);

	useEffect(() => {
		const qUserToken = Helpers.getUrlParameter('userToken')
		if (!qUserToken){
			localStorage.removeItem('userToken')
		}
	},[])
	const generateHomePage = async () => {
		// const homeData = localStorage.getItem('homeData');
		// if (homeData && (Date.now() - homeData.lastFetched < 5*60*1000) ) { // 5mins have passed
		//   setSectionsDataSorted(homeData.data);
		//   return;
		// }

		await setSectionsData([]);
		setSectionsDataSorted([]);

		const config =  Helpers.getAxiosHeadersConfig();

		const getSectionsUrl = `${process.env.REACT_APP_API_URL}/shopv2/sections${
			isWeb ? "?source=web" : ""
		}`;
		setLoadingPage(true);
		axios
			.get(getSectionsUrl, config)
			.then((res) => {
				let sectionsRes = res.data;
				if (sectionsRes.status === "success") {
					// or errorCode !== 0
					setNumberOfSections(sectionsRes.data.length);

					let orderOfSections = [];
					for (let sectionItem of sectionsRes.data) {
						orderOfSections = [...orderOfSections, sectionItem.id];

						let getSectionContentUrl = `${process.env.REACT_APP_API_URL}/shopv2/section`;

						if (sectionItem.itemType === 1 || sectionItem.itemType === 8) {
							// banner
							getSectionContentUrl = `${getSectionContentUrl}/banner?sectionId=${
								sectionItem.id
							}${isWeb ? "&source=web" : ""}`;
						} else if (
							sectionItem.itemType === 2 ||
							sectionItem.itemType === 5 ||
							sectionItem.itemType === 6 ||
							sectionItem.itemType === 7
						) {
							// product section
							getSectionContentUrl = `${getSectionContentUrl}/products?sectionId=${
								sectionItem.id
							}&page=0&maximumOffset=20${isWeb ? "&source=web" : ""}`;
						} else if (sectionItem.itemType === 3) {
							// celebrity shop
							getSectionContentUrl = `${getSectionContentUrl}/shops?sectionId=${
								sectionItem.id
							}${isWeb ? "&source=web" : ""}`;
						} else if (sectionItem.itemType === 4) {
							// ad
							getSectionContentUrl = `${getSectionContentUrl}/ad?sectionId=${
								sectionItem.id
							}${isWeb ? "&source=web" : ""}`;
						}

						axios
							.get(getSectionContentUrl, config)
							.then((itemRes) => {
								const sectionItemRes = itemRes.data;

								if (sectionItemRes.status === "success") {
									const sectionObj = {
										id: sectionItem.id,
										title: sectionItem.title,
										itemType: sectionItem.itemType,
										thumbnail: sectionItem.thumbnail,
										data: sectionItemRes.data,
									};

									setSectionsData((sectionsData) => [
										...sectionsData,
										sectionObj,
									]);
								} else {
									console.log("message", sectionItemRes.message);
								}
							})
							.catch((err) => {
								setLoadingPage(false);
								console.log("err", err);
							});
					}
					setSectionsOrder(orderOfSections);
					setLoadingPage(false);
				} else {
					setLoadingPage(false);
				}

				// setLoadingPage(false);
			})
			.catch((err) => {
				setLoadingPage(false);
			});
	};

	return (
		<>
			{isWeb ? <HeaderWeb /> : <Header loadingPage={loadingPage} />}

			<article
				className={`home-article ${
					isWeb && "container container-lg home-article-lg"
				}`}
			>
				{isWeb && <CustomerSupportButton />}
				{loadingPage && <div className="loader mt-5" />}

				{!loadingPage &&
					sectionsDataSorted &&
					sectionsDataSorted.length > 0 &&
					sectionsDataSorted.map((sectionItem) => (
						<Fragment key={sectionItem.id}>
							{(sectionItem.itemType === 1 || sectionItem.itemType === 8) && (
								<section className="banner-section pad">
									<Banner src={sectionItem.data.bannerContentURL} />
								</section>
							)}

							{sectionItem.itemType === 3 && (
								<section
									className={`shop-section ${isWeb && "shop-section-lg"}`}
								>
									<div className="section-header-div">
										<p className="section-title">{sectionItem.title}</p>
										<p className="vis-hidden">{t("view_all")}</p>
									</div>
									<div>
										<FreeCarousel
											ItemComponent={ShopItem}
											data={sectionItem.data ? sectionItem.data : []}
											sectionId={uuidv4()}
											minCount={7}
											childCount={sectionItem.data.length}
										/>
									</div>
								</section>
							)}

							{(sectionItem.itemType === 2 ||
								sectionItem.itemType === 5 ||
								sectionItem.itemType === 6 ||
								sectionItem.itemType === 7) && (
								<section
									className={`home-products-section ${
										isWeb && "home-products-section-lg"
									}`}
								>
									<div className="section-header-div">
										<div className='celebrity-section'>
											{sectionItem.thumbnail && (
												<div className="section-thumbnail-container">
													<img
														alt={sectionItem.title || 'category type'}
														src={sectionItem.thumbnail}
														className="section-thumbnail"
													/>
												</div>
											)}
											<p className="section-title">{sectionItem.title}</p>
										</div>
										<span
											onClick={() => handleViewSection(sectionItem)}
											className={`section-view-all ${
												sectionItem.thumbnail && "with-image"
											}`}
										>
											{t("view_all")}
										</span>
									</div>
									<div>
										<FreeCarousel
											ItemComponent={ProductItem}
											data={sectionItem.data ? sectionItem.data : []}
											showHalfOfNext
											sectionId={uuidv4()}
											minCount={isMobile ? 2 : 4}
											childCount={sectionItem.data.length}
										/>
									</div>
								</section>
							)}

							{sectionItem.itemType === 4 && (
								<section className="banner-section pad">
									{sectionItem.data.source === "adManager" && (
										<div className="admanager-ad">
											<DFPSlotsProvider dfpNetworkId="">
												<AdSlot
													adUnit={sectionItem.data.data.adUnitId}
													// adUnit='/27367402/360VUZ_ANDROID_TEST'
													sizes={[
														[
															sectionItem.data.data.width,
															sectionItem.data.data.height,
														],
													]}
												/>
											</DFPSlotsProvider>
										</div>
									)}
								</section>
							)}
						</Fragment>
					))}
			</article>
		</>
	);
};

export default Home;
