
const English = {
  view_all: 'View All',
  size: 'Size',
  description: 'Description',
  orders: 'My Orders',
  cs: 'Customer Support',
  cs_1: 'For your inquiries, returns and feedback:',
  cs_2: 'Please feel free to send us an email at',
  cs_3: 'Our support team will reply back to you and get you all the information and help you need as soon as possible.',
  redeem_with_points: 'Redeem With Points',
  buy_now: 'Buy Now',
  left: 'left',
  points: 'Points',
  merch: 'MERCH',
  view: 'View',
  no_orders: 'You haven\'t made any orders yet..',
  bought_on: 'Bought on',
  product_details: 'Product Details',
  shop: 'Shop',
  buy_points: 'Buy Points',
  day: 'day',
  day_left: 'day left',
  days_left: 'days left'


};

export default English;
