import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";

import BackHeader from "../layout/BackHeader";
import DynamicScrollToTop from "../layout/DynamicScrollToTop";
import ProductImage from "../shop/ProductImage";
import Carousel from "../shop/Carousel";
import CustomModal from "../shop/Modal";
import Helpers from "../../utils/Helpers";
import ProductDetailsAction from "../shop/ProductDetailsAction";
import HeaderWeb from "../layout/HeaderWeb";
import CustomerSupportButton from "../shop/CustomerSupportButton";
import Icons from "../../constants/Icons";

const ProductDetails = () => {
  const { t, i18n } = useTranslation();
  const appText = Helpers.getAppText();
  const routeParams = useParams();
  const isWeb = Helpers.getIsWeb();
  const isMobile = Helpers.getIsMobile();
  const productId = routeParams.productId;

  const userToken = Helpers.getUserToken() || "";
  const language =
    Helpers.getUrlParameter("language") ||
    localStorage.getItem("language") ||
    "en";
  const config = Helpers.getAxiosHeadersConfig();

  const i18nLang = localStorage.getItem("i18nextLng");
  const operationResult = Helpers.getUrlParameter("status") || "";
  const isRtl = language === "ar";
  const dir = isRtl ? "rtl" : "ltr";

  const [productDetails, setProductDetails] = useState({});
  const [productDetailsData, setProductDetailsData] = useState();
  const [productImages, setProductImages] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState({});
  const [productStock, setProductStock] = useState("");
  const [productDetailsLoading, setProductDetailsLoading] = useState(false);
  const [expiryCountdown, setExpiryCountdown] = useState("");
  const [isUserVip, setIsUserVip] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // const getProductDetailsUrl = `${process.env.REACT_APP_API_URL}/shopv2/get/product?productId=${productId}&shopId=${shopId}`;
  const getProductDetailsUrl = `${process.env.REACT_APP_API_URL}/shopv2/get/product?productId=${productId}`;
  const getUserProfileUrl = `${process.env.REACT_APP_API_URL}/subscribers/me/profile`;

  const getProductDetails = async () => {
    setProductDetailsLoading(true);

    try {
      const res = await axios.get(getProductDetailsUrl, config);
      let { data: productDetailsData, status, message } = res.data;
      if (status === "success") {
        setProductDetailsData(productDetailsData);
        const initialSize = productDetailsData.find((el) => {
          return el.billingOptions.length && el.stock;
        });

        setSelectedSize({
          id: initialSize.id,
          size: initialSize.attributes.size,
          stock: initialSize.stock,
          billingOptions: initialSize.billingOptions,
        });
      } else {
        console.log(message);
      }
      setProductDetailsLoading(false);
    } catch (err) {
      setProductDetailsLoading(false);
      console.log("getProductDetailsResponseError", err);
    }
  };

  useEffect(() => {
    if (productDetailsData) {
      const size = selectedSize.size || productDetailsData[0].attributes.size;
      const index = productDetailsData.findIndex(
        (el) => el.attributes.size === size
      );
      setProductDetails(productDetailsData[index]);
      setProductStock(productDetailsData[index].stock);

      if (productDetailsData[index].images.length > 0) {
        const allImages = new Set(productDetailsData.map((item) => item.images.map((image) => image.imageURL)).flat());
        const backgroundImages = productDetailsData.map((item) => item.backgroundImageURL);
        backgroundImages.forEach((bgImage) => allImages.delete(bgImage));

        setProductImages([backgroundImages[index], ...Array.from(allImages)]);
      } else {
        setProductImages([productDetailsData[index].backgroundImageURL]);
      }

      if (
        productDetailsData.length &&
        productDetailsData[index].attributes &&
        productDetailsData[index].attributes.size
      ) {
        let productsWithSizes = [];
        for (let p of productDetailsData) {
          productsWithSizes.push({
            id: p.id,
            size: p.attributes.size,
            stock: p.stock,
            billingOptions: p.billingOptions,
          });
        }
        setAvailableSizes(productsWithSizes);
      }
    }
  }, [productDetailsData, selectedSize]);

  const getUserIsVip = async () => {
    if (userToken) {
      try {
        const res = await axios.get(getUserProfileUrl, config);

        const { data: userData } = res.data;
        if (userData && userData.isPaid) {
          setIsUserVip(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (language !== i18nLang) {
      i18n.changeLanguage(language);
      Helpers.setDirection();
    }
    getProductDetails();
    getUserIsVip();
    // eslint-disable-next-line
  }, [i18nLang, language]);

  useEffect(() => {
    setProductStock(selectedSize.stock);
  }, [selectedSize]);

  const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  };

  useEffect(() => {
    if (productDetails && productDetails.expirationDate) {
      setInterval(() => {
        const then = moment(
          productDetails.expirationDate,
          "YYYY-MM-DD hh:mm:ss"
        );
        const now = moment();
        const countdown = then.diff(now);
        const tempTime = moment.duration(countdown);
        const days = tempTime.days();
        const hours = addLeadingZeros(tempTime.hours());
        const minutes = addLeadingZeros(tempTime.minutes());
        const seconds = addLeadingZeros(tempTime.seconds());
        if (days && days > 0) {
          if (days > 1) {
            setExpiryCountdown(`${days} ${appText.days_left}`);
          } else {
            setExpiryCountdown(`${days} ${appText.day_left}`);
          }
        } else {
          setExpiryCountdown(`${hours}:${minutes}:${seconds}`);
        }
      }, 1000);
    }
    // eslint-disable-next-line
  }, [productDetails]);

  const handleRedeem = () => {
    let finalProductId = productId;

    if (selectedSize && selectedSize.id) {
      finalProductId = selectedSize.id;
    }

    window.trackEvent("redeem_product_clicked", {
      source: "shop",
      product_id: finalProductId,
      is_guest: !userToken,
    });

    Helpers.runTrackEvents("redeem_points_screen", {
      product_id: finalProductId,
      product_name: productDetails.name,
    });
  };
  const handleBuy = () => {
    let finalProductId = productId;

    if (selectedSize && selectedSize.id) {
      finalProductId = selectedSize.id;
    }

    window.trackEvent("buy_product_clicked", {
      source: "shop",
      product_id: finalProductId,
      is_guest: !userToken,
    });

    Helpers.runTrackEvents("card_checkout_screen", {
      product_id: finalProductId,
      product_name: productDetails.name,
    });
  };

  useEffect(() => {
    if (operationResult && operationResult === "success") {
      setSuccessModal(true);
    }

    if (operationResult && operationResult === "error") {
      setErrorModal(true);
    }
  }, [operationResult]);

  return (
    <>
      {isWeb && !isMobile && <HeaderWeb />}
      {isWeb && !isMobile && <CustomerSupportButton />}
      <DynamicScrollToTop />
      <div
        className={`${isWeb && "container container-lg container-web"}`}
        dir={dir}
      >
        <BackHeader
          title={t("product_details")}
          url={!isWeb ? "/shop?userToken=" + userToken : "/shop"}
        />
        <section
          className={`product-details-section ${!isWeb && "handle-fixed-nav"}`}
        >
          {!isWeb && <div className="product-details-section-separator" />}
          {productDetailsLoading ? (
            <div className="loader mt-5" />
          ) : isWeb ? (
            <Row className="container product-details-web">
              <Col
                xs={isMobile ? 12 : 6}
                className={`product-details-img-div ${
                  isWeb && "product-details-img-div-lg"
                }`}
              >
                {productImages && productImages.length > 0 && (
                  <Carousel
                    ItemComponent={ProductImage}
                    data={productImages}
                    itemsToShow={1}
                    showDots
                    showArrows={isWeb && productImages.length > 1}
                  />
                )}
                {isMobile && (
                  <div
                    className={`amount-left-div ${
                      isWeb && "amount-center-div"
                    }`}
                  >
                    <img src={Icons.fire} className="fire-img mr-1" alt="" />
                    {productStock} {t("left")}
                  </div>
                )}
              </Col>

              <Col
                xs={isMobile ? 12 : 6}
                className={`product-details-div ${
                  isWeb && "product-details-div-lg"
                }`}
              >
                {expiryCountdown && (
                  <span className="product-expiration details">
                    {expiryCountdown}
                  </span>
                )}
                <div
                  className={`product-info-div ${
                    isWeb && "product-info-div-lg"
                  }`}
                >
                  {!isMobile && (
                    <div
                      className={`amount-left-div ${
                        isWeb && "amount-center-div"
                      }`}
                    >
                      <img src={Icons.fire} className="fire-img mr-1" alt="" />
                      {productStock} {t("left")}
                    </div>
                  )}

                  <p className="product-details-name">{productDetails.name}</p>
                  <div>
                    {productDetails.price &&
                      productDetails.price > 0 &&
                      !isWeb && (
                        <span>
                          {language === "ar" && (
                            <span className="diamond-text">
                              {productDetails.price}
                            </span>
                          )}
                          <img
                            src={Icons.diamond}
                            className="diamond-img2"
                            alt=""
                          />
                          {language === "en" && (
                            <span className="diamond-text">
                              {productDetails.price}
                            </span>
                          )}
                        </span>
                      )}

                    {productDetails.priceMoney &&
                      productDetails.priceMoney > 0 && (
                        <span>
                          <span className="dollar-text">
                            ${productDetails.priceMoney}
                          </span>
                        </span>
                      )}
                  </div>

                  <div className="product-details-sections">
                    {availableSizes && availableSizes.length > 0 && (
                      <p className="product-details-section-title">
                        {t("size")}
                      </p>
                    )}
                    <div className="product-details-sizes-div">
                      {availableSizes &&
                        availableSizes.length > 0 &&
                        availableSizes.map((sizeItem) => (
                          <button
                            key={sizeItem.id}
                            onClick={() => setSelectedSize(sizeItem)}
                            className={`size-btn ${
                              selectedSize.size === sizeItem.size && "selected"
                            } ${
                              (sizeItem.stock <= 0 ||
                                !sizeItem.billingOptions.length) &&
                              "disabled"
                            } `}
                            disabled={
                              sizeItem.stock <= 0 ||
                              !sizeItem.billingOptions.length
                            }
                          >
                            {sizeItem.size}
                          </button>
                        ))}
                    </div>

                    {productDetails.description && (
                      <div className="mt-2">
                        <p className="product-details-section-title">
                          {t("description")}
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: productDetails.description,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {isWeb && (
                  <ProductDetailsAction
                    productDetails={productDetails}
                    isUserVip={isUserVip}
                    productId={productId}
                    handleRedeem={handleRedeem}
                    handleBuy={handleBuy}
                    selectedSize={selectedSize}
                  />
                )}
              </Col>
            </Row>
          ) : (
            <>
              <div className="product-details-div">
                {/* <img src={vipBadge} className='vip-badge-img-lg' /> */}
                {expiryCountdown && (
                  <span className="product-expiration details">
                    {expiryCountdown}
                  </span>
                )}

                <div className="product-details-img-div">
                  {/* {productImages && productImages.length === 1 && <ProductImage data={productImages[0]} />} */}

                  {productImages && productImages.length > 0 && (
                    <Carousel
                      ItemComponent={ProductImage}
                      data={productImages}
                      itemsToShow={1}
                      showDots
                    />
                  )}

                  <div className="amount-left-div">
                    <img src={Icons.fire} className="fire-img mr-1" alt="" />
                    {productStock} {appText.left}
                  </div>
                </div>
                <div className="product-info-div">
                  <p className="product-details-name">{productDetails.name}</p>
                  <div>
                    {productDetails.price && productDetails.price > 0 && (
                      <span>
                        {language === "ar" && (
                          <span className="diamond-text">
                            {productDetails.price}
                          </span>
                        )}
                        <img
                          src={Icons.diamond}
                          className="diamond-img2"
                          alt=""
                        />
                        {/* <span className='diamond-text'>{productDetails.price}</span> */}
                        {language === "en" && (
                          <span className="diamond-text">
                            {productDetails.price}
                          </span>
                        )}
                      </span>
                    )}

                    {productDetails.priceMoney &&
                      productDetails.priceMoney > 0 && (
                        <span>
                          <span className="dollar-text">
                            ${productDetails.priceMoney}
                          </span>
                        </span>
                      )}
                  </div>

                  <div className="product-details-sections">
                    {availableSizes && availableSizes.length > 0 && (
                      <p className="product-details-section-title">
                        {appText.size}
                      </p>
                    )}
                    <div className="product-details-sizes-div">
                      {availableSizes &&
                        availableSizes.length > 0 &&
                        availableSizes.map((sizeItem) => (
                          <button
                            key={sizeItem.id}
                            onClick={() => setSelectedSize(sizeItem)}
                            className={`size-btn ${
                              selectedSize.size === sizeItem.size && "selected"
                            } ${
                              (sizeItem.stock <= 0 ||
                                !sizeItem.billingOptions.length) &&
                              "disabled"
                            } `}
                            disabled={
                              sizeItem.stock <= 0 ||
                              !sizeItem.billingOptions.length
                            }
                          >
                            {sizeItem.size}
                          </button>
                        ))}
                    </div>

                    {productDetails.description && (
                      <div className="mt-2">
                        <p className="product-details-section-title">
                          {appText.description}
                        </p>
                        {/* <p className='product-details-description'>{productDetails.description}</p> */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: productDetails.description,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <ProductDetailsAction
                productDetails={productDetails}
                isUserVip={isUserVip}
                handleBuy={handleBuy}
                handleRedeem={handleRedeem}
                selectedSize={selectedSize}
                productId={productId}
              />
            </>
          )}
          <CustomModal
            icon={<img alt="" src={Icons.errorIcon} width={140} />}
            description={
              <>
                <h5>{t("smth_went_wrong")}</h5>
              </>
            }
            text={t("ok")}
            show={errorModal}
            onHide={() => setErrorModal(false)}
          />
          <CustomModal
            className="success"
            icon={<img alt="" src={Icons.success} width={140} />}
            description={
              <>
                <h5>{t("order_placed")}</h5>
                <p>{t("order_placed_successfully")}</p>
              </>
            }
            text={t("continue_shopping")}
            show={successModal}
            action
            onHide={() => setSuccessModal(false)}
          />
        </section>
      </div>
    </>
  );
};

export default ProductDetails;
