import React from "react";
import { Col, Row } from "react-bootstrap";
import Icons from "../../constants/Icons";
import { useTranslation } from "react-i18next";

const CheckoutProductDetails = ({ paymentOption, productDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <Row className="product-details-title">{t("summary")}</Row>
      <Row>
        <Col className="col-2" style={{ maxWidth: "fit-content" }}>
          <img
            src={productDetails?.backgroundImageURL}
            className="order-product-img order-product-img-lg"
            alt=""
          />
        </Col>
        <Col className="product-data col">
          <span className="product-name">{productDetails.name}</span>
          <div className="product-details-info">
            {productDetails?.attributes?.size && (
              <span className="order-details-span">
                {productDetails?.attributes?.size?.toUpperCase()}
              </span>
            )}
            {productDetails.color && (
              <span
                className="product-color"
                style={{ background: productDetails.color }}
              />
            )}
            {paymentOption === "points"
              ? productDetails.price &&
                productDetails.price > 0 && (
                  <>
                    <img
                      src={Icons.diamond}
                      className="diamond-img2"
                      alt="diamond"
                    />
                    <span className="diamond-text">{productDetails.price}</span>
                  </>
                )
              : productDetails.priceMoney && (
                  <span className="product-price">
                    ${parseFloat(productDetails.priceMoney)}
                  </span>
                )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CheckoutProductDetails;
