import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CustomerSupportButton from "../shop/CustomerSupportButton";
import BackHeader from "../layout/BackHeader";
import DynamicScrollToTop from "../layout/DynamicScrollToTop";
import HeaderWeb from "../layout/HeaderWeb";
import ProductItem from "../shop/ProductItem";
import Helpers from "../../utils/Helpers";
import useWindowWidth from "../hooks/UseWindowWidth";

const SectionListing = () => {
  const { i18n } = useTranslation();
  const isWeb = Helpers.getIsWeb();
  const isMobile = useWindowWidth() <= 768;
  const routeParams = useParams();
  const navigate = useNavigate();
  const i18nLang = localStorage.getItem("i18nextLng");
  const lang = localStorage.getItem("language");
  const userToken = Helpers.getUserToken();

  const [sectionProductsLoading, setSectionProductsLoading] = useState(false);
  const [sectionProducts, setSectionProducts] = useState([]);
  const [section, setSection] = useState({
    id: routeParams.id,
    title: Helpers.getUrlParameter("title") || "",
    type: Helpers.getUrlParameter("type") === "2" ? "products" : "shops",
  });

  const getSection = async () => {
    const getShopSectionUrl = `${
      process.env.REACT_APP_API_URL
    }/shopv2/sections${isWeb ? "?source=web" : ""}`;
    const config = Helpers.getAxiosHeadersConfig();

    try {
      const res = await axios.get(getShopSectionUrl, config);
      const { data } = res.data;
      for (let sectionItem of data) {
        if (+sectionItem.id === +section.id) {
          setSection({ ...section, title: sectionItem.title });
          break;
        }
      }
    } catch (err) {
      console.log("getShopSectionUrlResErr", err);
    }
  };

  const getSectionProductsUrl = `${
    process.env.REACT_APP_API_URL
  }/shopv2/section/products?sectionId=${section.id}${
    isWeb ? "&source=web" : ""
  }`;
  const getSectionProducts = async () => {
    setSectionProductsLoading(true);
    const config = Helpers.getAxiosHeadersConfig();

    try {
      const res = await axios.get(getSectionProductsUrl, config);
      let { data: sectionProductsData, status, message } = res.data;
      if (!sectionProductsData.length) {
        if (isWeb) {
          navigate("/shop");
          return;
        } else {
          navigate(`/shop?userToken=${userToken}`);
          return;
        }
      }
      if (status === "success") {
        setSectionProducts(sectionProductsData);
      } else {
        console.log(message);
      }
      setSectionProductsLoading(false);
    } catch (err) {
      setSectionProductsLoading(false);
      console.log("getProductDetailsResponseError", err);
    }
  };

  useEffect(() => {
    if (lang !== i18nLang) {
      i18n.changeLanguage(lang);
      Helpers.setDirection();
    }

    if (section.title === "" || isWeb) {
      // need to make an API call to get section name
      // this case happens when navigation is from app shopId param
      getSection();
    }

    getSectionProducts();
  }, [i18nLang, lang]);

  return routeParams.id !== "0" ? (
    <>
      {isWeb && !isMobile && <HeaderWeb />}
      {isWeb && <CustomerSupportButton />}
      <DynamicScrollToTop />
      <div className={isWeb && "container container-lg container-web-grid"}>
        <BackHeader prev="/" title={section.title} />
        <section className="shop-products-section handle-fixed-nav">
          <Container className={`${isWeb ? "container-lg" : "mt-2"}`}>
            {sectionProductsLoading ? (
              <div className="loader mt-5" />
            ) : (
              <Row>
                {sectionProducts &&
                  sectionProducts.length > 0 &&
                  sectionProducts.map((productItem) => (
                    <Col
                      md={3}
                      sm={4}
                      xs={6}
                      className="product-col"
                      key={Math.random() + productItem.id}
                    >
                      <ProductItem data={productItem} />
                    </Col>
                  ))}
              </Row>
            )}
          </Container>
        </section>
      </div>
    </>
  ) : null;
};

export default SectionListing;
