import axios from "axios";

export default {
  getStripeCheckoutData: async (payload, config) => {
    const url = `${process.env.REACT_APP_API_URL}/paymentsV3/get-stripe-checkout-data`;

    try {
      const res = await axios.post(url, payload, config);
      const data = res.data;

      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getRedeemCheckoutData: async (payload, config) => {
    const url = `${process.env.REACT_APP_API_URL}/payments/process/product/redeemusingpointsV2`

    try {
      const res = await axios.post(url, payload, config);
      const data = res.data;
      console.log(res)
      return data;
    } catch (err) {
      console.log(err)
    }
  }
};
