import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5PP2Z7SF'
}

TagManager.initialize(tagManagerArgs)

createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
