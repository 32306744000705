import React, { useEffect, useState } from "react";
import Icons from "../../constants/Icons";

const Success = () => {
  const [link, setLink] = useState('')

  useEffect(() => {
    let fromPath = localStorage.getItem('fromPath')
    if (fromPath) {
      localStorage.removeItem('fromPath')
      setLink(fromPath)
      return
    }
    setLink(fromPath)
  }, [])

    

  return (
    <div className="success-section">
      <img src={Icons.success} alt="" className="img-fluid" />
      <p className="order-placed">Thank you for your order!</p>
      <br />
      <a
        className="confirm-btn"
        href={link}
      >
        Continue Shopping
      </a>
        {link}
    </div>
  );
};

export default Success;
