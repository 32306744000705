import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Helpers from '../../utils/Helpers';

const CustomModal = ({
  className,
  icon,
  description,
  action = false,
  onHide,
  text,
  ...props
}) => {
  const isWeb = Helpers.getIsWeb()
  const { i18n } = useTranslation();
  const userToken = Helpers.getUserToken() || ''
  const isRtl = i18n.dir() === 'rtl';
  const goToHomepage = () => {
    if (!isWeb) {
      window.location.href = `/shop?userToken=${userToken}`
    } else {
      window.location.href = '/shop'
    }
  };

  return (
    <Modal
      {...props}
      className={`modal ${className}`}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img'>
          {icon}
        </div>
        <div className='modal-desc'>{description}</div>
      </Modal.Body>
      {text ? 
      <Modal.Footer>
        <Button className={isRtl && 'mt-4'} onClick={action ? goToHomepage : onHide}>{text}</Button>
      </Modal.Footer> : null}
    </Modal>
  )
}

export default CustomModal;
