import React from "react";
import { Route, Routes as Router } from "react-router-dom";

import Ads from "../views/dev/Ads";
import Home from "../views/Home";
import Orders from "../views/Orders";
import ProductDetails from "../views/ProductDetails";
import SectionListing from "../views/SectionListing";
import CustomerSupport from "../views/CustomerSupport";
import Success from "../views/Success";
import OrderSuccess from "../views/OrderSuccess";
import Checkout from "../views/Checkout";
import WebContainer from "../shop/WebContainer";

const Routes = () => {
  return (
    <section className="page-section">
      <Router>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shop" element={<Home />} />
        <Route exact path="/shop/home" element={<Home />} />
        <Route exact path="/shop/ads" element={<Ads />} />
        <Route exact path="/shop/orders" element={<Orders />} />
        <Route exact path="/shop/success" element={<Success />} />
        <Route exact path="/shop/customer-support" element={<CustomerSupport />} />
        <Route exact path="/shop/sections/:id" element={<SectionListing />} />
        <Route
          exact
          path="/shop/products/:productId"
          element={
            <WebContainer>
              <ProductDetails />
            </WebContainer>
          }
        />
        <Route
          exact
          path="/shop/sections/:sectionId/products/:productId"
          element={
            <WebContainer>
              <ProductDetails />
            </WebContainer>
          }
        />

        <Route exact path="/shop/order-success" element={<OrderSuccess />} />
        <Route
          exact
          path="/shop/products/:productId/buy"
          element={
            <WebContainer>
              <Checkout />
            </WebContainer>
          }
        />
        <Route path="*" element={<Home />} />
      </Router>
    </section>
  );
};

export default Routes;
