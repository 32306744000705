import React from 'react';

import Icons from '../../constants/Icons';

const CarouselButtons = ({
  scroll,
  scrollLimit,
  scrollable,
  isDirRtl,
  maxScroll,
  isScrollStart,
  scrollEnd,
  handleCarouselScroll
}) => {
  return (
    <div>
      {(
        (!isDirRtl && !isScrollStart)
        ||
        (isDirRtl && scrollable && scroll !== -scrollLimit && !scrollEnd))
        &&
        (
          <img
            className={`carousel-button carousel-button-back ${isDirRtl && 'carousel-button-back-rtl'}`}
            onClick={() => handleCarouselScroll(true)}
            src={Icons.carouselBtn}
            alt=''
          />
      )}
      {(
        (!isDirRtl && scrollable && (scroll !== scrollLimit) && !scrollEnd)
        ||
        (isDirRtl && !isScrollStart))
        && (
          <img
            className='carousel-button'
            onClick={() => handleCarouselScroll(false)}
            src={Icons.carouselBtn}
            alt=''
          />
      )}
    </div>
  )
}

export default CarouselButtons;
