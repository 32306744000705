import React from 'react';
import { useTranslation } from 'react-i18next';

import Icons from '../../constants/Icons';

const OrderSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className='success-section'>
      <img src={Icons.success} alt='' className='img-fluid' />
      <p className='order-placed'>{t('order_placed')}</p>

      <br/>
      <a className='huge-link blue' href={`${window.location.origin}/shop`}>
        {t('continue_shopping')}
      </a>
    </div>
  );
};

export default OrderSuccess;
